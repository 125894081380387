const colors={
    bgLightBlue:"#96C3FC",
    bgLightYello:"#FBE09B",
    bbgLightWhite:"#F8F4F5",
    bgLightBlue2:"#D3DFED",
    bgLightGreen:"#B2DDE4",
    bgLightGray:"#ACB2B2",
    tableHeader:"#D9D9D9",





    bgDarkBlue:"#1C5F92"
    
    }
    export default colors;