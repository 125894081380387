import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';
import {styles} from '../../../assets/styleSAP/formaInputSap'

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import axios from 'axios';

import useTable from "../../../assets/hooks/useTable";
import { api } from '../../../assets/assets';
import TableShow from '../../table/TableShow';
import TableDelete from '../../table/TableDelete';





const SearchAreas = ({navigation}) => {
    
  const apilink='Area';
  const pagetoEdit='EditArea'

  const [data,setData]=useState([])
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);



  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);
  const[loading,setloading]=useState(0)

  const hide=[{header :"created_at"},{header :"updated_at"}]


const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
    })
    .catch((error) => {
        console.log(error);
    });
}

  // Update the table data when the incoming data prop changes
  useEffect(() => {
    axios({
      method: "get",
      url: api+apilink,
      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      },
  })
  .then((response) => {
       console.log(response.data);
       setTableData(response.data);
       setData(response.data);
    setHeaderColumns(Object.keys(response.data[0] || {}));
    setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
    setloading(1)
  })
  .catch((error) => {
      console.log(error);
  });
    
  }, []);


  const toggleColumn = (header) => {
    if (hiddenColumns.includes(header)) {
      // If the column is hidden, show it
      setHiddenColumns(hiddenColumns.filter((column) => column !== header));
      setHeaderColumns([...headerColumns, header]);
      setTableData(
        tableData.map((item) => {
          return { ...item, [header]: hiddenColumns.includes(header) ? item[header] : null };
        })
      );
    } else {
      // If the column is shown, hide it
      setHiddenColumns([...hiddenColumns, header]);
      setHeaderColumns(headerColumns.filter((column) => column !== header));
      setTableData(
        tableData.map((item) => {
          const { [header]: value, ...rest } = item;
          return rest;
        })
      );
    }
  };


 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  // Handle filtering the table when the search input changes
  const handleSearch = (text) => {setSearch(text); setTableData(TableHandleSearch(text,data));};



// Render each row of the table as a FlatList item
const renderItem = ({ item }) => {
  return (
   

  <View style={styles.row}>
    
   
  {headerColumns.map((header,index) => (

  <Text key={header} style={styles.cell}>
  {item[header]}
  </Text>
  ))}
  <TouchableOpacity 
  onPress={()=>navigation.navigate(pagetoEdit,
  {item:item})}
  >
  <Text style={[styles.cell,{color:"blue"}]}>
           تعديل
           </Text>
          </TouchableOpacity>
  </View>
 
  );
  };

  const renderHeader = () => {
    return (
      <View style={{ flexDirection: "row", backgroundColor: "lightgray" }}>
        {headerColumns.map((header, index) => (
          <Text key={index} style={styles.cell}>
            {header}
          </Text>
        ))}
         <Text  style={styles.cell}>
           تعديل
          </Text>
      </View>
    );
  };
  
  // Calculate the total number of pages based on the number of rows and the max rows per page
  const totalPages = Math.ceil(tableData.length / 20);
  
  // Create an array of page numbers to render page buttons
  const pageNumbers = Array.from(Array(totalPages), (_, i) => i + 1);
  
  // Filter the table data based on the current page
  const startIndex = (currentPage - 1) * 20;
  const endIndex = startIndex + 20;
  const paginatedData = tableData.slice(startIndex, endIndex);
  
  return (
    <View style={[styles.container,{minHeight:1080}]}>
    <NavBar navigation={navigation}/>
    <NavBar2 navigation={navigation} nav2active={4}/>
    <NavBar3 navigation={navigation} nav2active={2}/>



      

    <View style={styles.container2}>
        <Text >Areas table page</Text>

        {loading==0?null: <TableShow
        apilink={apilink}
     pagetoEdit1={pagetoEdit}
     data1={data}
     countTabel={data.length}
     tableData1={tableData}
     headerColumns1={headerColumns}
     headerColumnsOrginal1={headerColumnsOrginal}
     hide={hide}
     navigation={navigation}/>}

  </View>



  

  
  </View>
  );
  };
  


export default SearchAreas;