import navs from "./navs";

const initalState = {
    nav3:[],      
};
const navbar3Reducer=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){

case "setCountryNav3":return{...state,nav3:navs.countryNav3}
case "setCompanyNav3":return{...state,nav3:navs.companyNav3}
case "chefNav3":return{...state,nav3:navs.chefNav3}
case "setUsersNav3":return{...state,nav3:navs.userNav3}
case "setSubscribeNav3":return{...state,nav3:navs.subscribeNav3}
case "setPackagesNav3":return{...state,nav3:navs.packagesNav3}
case "setAreasNav3":return{...state,nav3:navs.areasNav3}
case "setVersionsNav3":return{...state,nav3:navs.versionsNav3}
case "setitemsNav3":return{...state,nav3:navs.itemsNav3}
case "categoryNav3":return{...state,nav3:navs.categoryNav3}
case "discountCodesNav3":return{...state,nav3:navs.discountCodesNav3}
case "deleviryNav3":return{...state,nav3:navs.deleviryNav3}
case "PrintVav3":return{...state,nav3:navs.PrintVav3}
case "chefNav4":return{...state,nav3:navs.chefNav4}





case "resetNav3" : return{...state,nav3:[]}





}



return state;
}
export default navbar3Reducer;