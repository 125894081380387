import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colors';

const { width, height } = Dimensions.get('window');

const Poppins_Medium = require('../fonts/Poppins-SemiBold.ttf');
const AlmaraiـBold = require('../fonts/Almarai/Almarai-Bold.ttf');



export const searchPicker = StyleSheet.create({
    container: {
        marginBottom:300,
        backgroundColor:"white"
       },
       datebtn:{
        padding:10,
        backgroundColor:colors.grayFont,
        width:400,
        margin:20,
        borderRadius:10
       },
       modalContainer:{
        padding:20,
        backgroundColor:colors.grayFont,
        flex:1
       },
       closeButton:{
        margin:20,
        width:200,
        borderRadius:10,
        backgroundColor:colors.white,
        padding:10,
        alignItems:"center"
       },
       closeButtonText:{
        color:"blue"
       },
       searchInput:{
        padding:10,
        width:"100%",
        backgroundColor:colors.backgroundGray,
        borderRadius:10,
        color:colors.white
       },
       pickerItem:{
        padding:5,
        backgroundColor:colors.white,
        margin:2
       }

     

    });