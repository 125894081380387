import React from "react";



const useExport = () => {

  const exportCSV = async (headersToInclude, jsonData) => {
    // Extract headers
    const headers = headersToInclude.join(',');

    // Map JSON data to include only specified headers
    const rows = jsonData.map(item => {
        return headersToInclude.map(header => item[header] || '').join(',');
    });

    // Combine headers and rows
    const csvContent = [headers, ...rows].join('\n');

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');
    if (link.download !== undefined) {
        // Set the download attribute with a filename
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};


      return[exportCSV]




}
export default useExport;