const initalState = {
    loginState:"false" ,
    phone:"",
    name:""
};
const login=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){
case "login":
    return{...state,
        loginState:"true",
        phone:payload.phone,
        name:payload.name
    }

case "logout":
    return{...state,
        loginState:"false",
        phone:"",
        name:""
    }
  
    
            

}



return state;
}
export default login;