const initalState = {
};
const language=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){

            

}



return state;
}
export default language;