import navs from "./navs";

const initalState = {
    nav2:[],      
};
const navbar2Reducer=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){


case "setCountry":return{...state,nav2:navs.countryNav2}

case "setDataEntry":return{...state,nav2:navs.dataEntryNav2}

case "SetNavKitchin":return{...state,nav2:navs.KitchinNav2}

case "setCompany": return {...state,nav2:navs.companyNav2}


case "setChef": return {...state,nav2:navs.chefNav2}

case "setadmin": return {...state,nav2:navs.adminNav2}

}



return state;
}
export default navbar2Reducer;