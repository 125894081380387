import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colorsSAP';

export const styles = StyleSheet.create({
    container:{
        backgroundColor:colors.bbgLightWhite,
        borderWidth:0.5,
        margin:3,
        padding:5
    },
    container2:{
        margin:5,
        backgroundColor:colors.bbgLightWhite,
        borderWidth:0.5,
        borderTopWidth:4,
        borderRadius:10,
        borderColor:colors.bgDarkBlue,
        backgroundColor:colors.bgLightBlue2
    },

    formaView:{
        borderWidth:1.5,
        margin:15,
        borderColor:"blabk",

        paddingBottom:10

    }, formaView3:{
        borderWidth:1.5,
        margin:15,
        borderColor:"blabk",
        padding:20,
        paddingBottom:10

    },
    formaView2:{
        borderWidth:1.5,
        margin:15,
        borderColor:"blabk",
        borderRadius:5,
        backgroundColor:"white",

        paddingBottom:10

    },
    formaViewHeader:{
        backgroundColor:colors.bgLightGreen,
        width:130,
        borderWidth:0.5,borderColor:colors.bgLightBlue
        ,borderBottomRightRadius:300,
        padding:3,
        flexDirection:"row",alignItems:"center"
    },
    inputView:{
        flexDirection:"row" ,padding:5,
       alignItems:"center"
    },
    inputHeaderText:{
        marginRight:5

    },
    TextInput:{
        width:230,
        backgroundColor:colors.bbgLightWhite
        ,padding:5,borderWidth:0.5,borderColor:colors.bgLightGray
    },


    formaViewRow:{

    },

    Button:{
        width:150,
        height:30, 
        alignItems:"center",
        justifyContent:"space-between",
        margin: 10,
                borderRadius: 3,
                border: 1,
                borderColor: '#ddd',
                padding: 3,
                paddingLeft: 15,
                paddingRight: 15,
                backgroundColor: '#FBE09B',
                transition: 'all 0.1s ease-in',
                borderColor: '#707070',
                borderWidth:0.6,
                shadowColor: '#000000',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowRadius: 5,
                shadowOpacity: 1,
                flexDirection:"row"
    },



    tableHeaderView:{
        flexDirection: "row", backgroundColor: colors.tableHeader,borderWidth:0.2
    },

    cellHeader: {
        flex: 1,
        alignItems: "center",
        textAlign:"center",
        justifyContent: "center",
        width:240,
        borderWidth:0.2,
        padding:5,
        borderColor:colors.bgLightGray
        },
        cell: {
            flex: 1,
            alignItems: "center",
            textAlign:"center",
            justifyContent: "center",
            backgroundColor:colors.bbgLightWhite,
            width:240,
            borderWidth:0.02,borderColor:colors.bgLightGray
            ,padding:5,
            },

            row: {
                flexDirection: "row",
                },
    pageContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginVertical: 10,
        },
    pageButton: {
        borderWidth: 1,
        borderColor: "#ccc",
        borderRadius: 5,
        paddingVertical: 5,
        paddingHorizontal: 10,
        marginHorizontal:5,
        },
        currentPageButton: {
            backgroundColor: "#A9A7AB",
            },
            currentPageButtonText: {
                color: "#fff",
                },

                ViewInput4Row:{flexDirection:"row",justifyContent:"space-between",alignItems:"center"},
                ViewInput2Row:{flexDirection:"row",alignItems:"center",marginTop:10},

                printBTN:{
                    width:100,
                   
                    backgroundColor:'red',
                    color:"white",
                    alignItems:"center",    
                    flexDirection:"row",
                    padding:15,
                    borderRadius:15,
                    margin:10
                }


})