import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';
import Table from '../../table/Table';
import dateicon from '../../../assets/iconpng/newicons/date.png'
import notchose from '../../../assets/iconpng/newicons/notchose.png'
import printicon from '../../../assets/iconpng/newicons/print.png'
import excel from '../../../assets/iconpng/newicons/excel.png'
import mealicon from '../../../assets/iconpng/newicons/meal.png'
import useExport from './useExport';



const MealsAnalysis =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);
    const lang=useSelector(state =>state.navbar.language);


    //count not select
    const [notSelect,setNotSelect]=useState(0);

    // select date 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datestring,setdatestring]= useState()

    const [loading, setLoading] = useState(false);

    


    //  function controller larvael to select component for item from table 'component_item' where 'component_item.item_id' =  $meal->item_id and join whit table 'components' where component_item.component_id = components.id and join with table 'units' where components.id components.unit_id = units.id

      const [exportCSV] = useExport();
      const headers=['nameEN','nameAR','count', 'totalcarb','totalprotein']
      const csv=()=>{
        const  newArray =  items
        .filter(item => item.count !== 0) // Filter out items with count == 0

        .map(item => {
         const totalprotein=  countCarbProtein(item.subscriptions).totalprotien
        const totalcarb=  countCarbProtein(item.subscriptions).totalcarb
          return {
              ...item,
              totalcarb,
              totalprotein
          };
      });

      exportCSV(headers,newArray)


        
      }
    //chefItems
    const [items,setItems]=useState([])
  
    const handleDateChange = (date) => {
      setLoading(true)
      setSelectedDate(date);
      setShowDatePicker(false);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");

      //set day
      const day = String(date.getDate()).padStart(2, "0");
      console.log(`${year}-${month}-${day}`)
      setdatestring(`${year}-${month}-${day}`)

      axios({
        method: 'post',
        url: api+'searchChefMealsByDateCountSub',
        headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
        },
        data:{
         
          date:`${year}-${month}-${day}`
        
        }
      })
      .then(response => {
       setItems(response.data)
       console.log(response.data)
       axios({
        method: 'post',
        url: api+'countNotSelect',
        headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
        },
        data:{
         
          date:`${year}-${month}-${day}`
        
        }
      })
      .then(response => {
       setNotSelect(response.data)
       console.log(response.data)
       setLoading(false)

       if(Number(response.data)!=0){
        alert(
          lang=="ar"?
          "الرجاء ادخال الوجبات لغير المختارين اولا لوجباتهم"
          :
          "please enter the meals for the not selected first for their meals"
        
        )
       }

      })
      .catch(error => {
        console.log(error)
        //seterror(error.message);

      });

      })
      .catch(error => {
        console.log(error)
        //seterror(error.message);

      });

     

    };
  
    const dateString = selectedDate ? selectedDate.toISOString().slice(0, 10) : '';
    // end select date 
  
    function countCarbProtein(array) {
      let counts = {};
      for (let i = 0; i < array.length; i++) {
        let item = array[i];
        let key = "carbs " + item.carbs + " - protein " + item.protein;
        counts[key] = (counts[key] || 0) + 1;
      }
      let totalcarb=0;
      let totalprotien=0;

       Object.entries(counts).map(([key, count]) => {
        const [carbs, protein] = key.split(' - ');
        totalcarb=totalcarb+( Number(parseInt(carbs.split(' ')[1])) * Number(count) );
        totalprotien=totalprotien+( Number(parseInt(protein.split(' ')[1])) * Number(count) );

      });
      console.log({totalcarb:totalcarb,totalprotien:totalprotien})
      return ({totalcarb:totalcarb,totalprotien:totalprotien});
    }
     
    return(
      loading?<View><Text>loading...</Text></View>:
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
      <View style={styles.container2}>
        <Text >meals analysis page</Text>

          

          {/* all text input */}

           
   {/*Date  */}
   <View style={styles.formaView}>
<View style={styles.formaViewHeader}>
<Image 
    source={dateicon}  
    style={{width: 24, height: 24 }}
/>
  <Text>Date</Text></View>

   {/* Select the date*/}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the date</Text>
{!showDatePicker&& <TouchableOpacity onPress={() => setShowDatePicker(true)}>
    <View   style={styles.TextInput}>
    <Text> {datestring}</Text>

    </View>
</TouchableOpacity>}

{showDatePicker && (
<View  style={datePickerStyles.container}>
<DateTimePicker

value={selectedDate}
onChange={handleDateChange}
format="yyyy-MM-dd"
style={datePickerStyles.container}



/></View>)}

</View>
 {/*end of Select the date*/}

</View>
{/* end of Date */}

   {/*Date  */}
   <View style={styles.formaView}>
<View style={styles.formaViewHeader}>
<Image 
    source={notchose}  
    style={{width: 24, height: 24 }}
/>
  <Text>not select</Text></View>

   {/* Select the date*/}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>not select</Text>

<View         style={styles.TextInput}
>
<Text>{notSelect}</Text>
</View>
</View>
 {/*end of Select the date*/}

 <TouchableOpacity style={styles.Button}        onPress={()=>window.print()}
>
<Image 
    source={printicon}  
    style={{width: 24, height: 24 }}
/>
                    <Text style={styles.saveBtnText}>print page</Text>
                </TouchableOpacity>




                <TouchableOpacity style={styles.Button}        onPress={()=>csv()}
>
<Image 
    source={excel}  
    style={{width: 24, height: 24 }}
/>
                    <Text style={styles.saveBtnText}>CSV</Text>
                </TouchableOpacity>

</View>
{/* end of Date */}


   {/*Table  */}
   {Number(notSelect)===0?
   <View style={styles.formaView}>
   <View style={styles.formaViewHeader}>
   <Image 
    source={mealicon}  
    style={{width: 24, height: 24 }}
/>
    
    <Text>meals Table</Text></View>
   
   {/* table View */}
   <View style={{marginVertical:15,marginHorizontal:10}}>
     {/* TableHeader */}
   <View style={styles.tableHeaderView}>
   <Text  style={styles.cellHeader}>
               total protien
             </Text> 
             <Text  style={styles.cellHeader}>
               total carb
             </Text>  
             
             <Text  style={styles.cellHeader}>
               count
             </Text>
           <Text  style={styles.cellHeader}>
               meal
       
             </Text>
             
            
      
         </View>
           {/* end of TableHeader */}
   
           {
      items&&
      items.map((item,index)=>
      item.count=="0"?null:
      <TouchableOpacity 
      key={index}
      onPress={()=>navigation.navigate('MealsDetails',{
       item:item,datestring:datestring
      })}
      style={styles.row}
      >
       
       <Text style={styles.cell}>
               {countCarbProtein(item.subscriptions).totalprotien}
               </Text>

               <Text style={styles.cell}>
               {countCarbProtein(item.subscriptions).totalcarb}
               </Text>
               
               <Text style={styles.cell}>
               {item.count}
               </Text>
               <Text style={styles.cell}>
       {lang=="ar"?item.nameAR:item.nameEN}
               </Text>
       
       
       </TouchableOpacity>
      )
   }
   
   
   </View>
   {/* end table View */}
   </View>
   :null}
   
 {/*end of Select the date*/}


















      







        </View>
  

           
</View>
    )
}

export default MealsAnalysis;