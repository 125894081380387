import React,{useState,Component,useRef,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
,StyleSheet,Image,Animated
} from 'react-native'
import {useDispatch,useSelector} from "react-redux";
import {styles} from "../../assets/styleSAP/NavBarStylesSAP3"
import logoutpng from '../../assets/iconpng/logout.png'
import loadinggif from '../../assets/icongif/loadingwhite.gif'
import done from '../../assets/icongif/donewhite.gif'
import { motion } from "framer-motion";




const NavBar3 =({navigation,nav2active})=>{
    const[loading,setLoading]=useState(0)
    const login=useSelector(state => state.login)
    const dispatch = useDispatch();
    const lang=useSelector(state =>state.navbar.language);

    // const navActive=useSelector(state =>state.navbar.nav2Active);
    const[active,setActive]=useState(nav2active)

    const [isOn, setIsOn] = useState(lang==="en"?false:true);


   






    const [isHovered, setIsHovered] = useState(0);

    let i ='KitchinStack'
    const nav=useSelector(state =>state.navbar3Reducer.nav3);


    const logout=()=>{
        setLoading(1)
         setTimeout(function(){
            setLoading(2)
            
        }, 700);
        setTimeout(function(){
            dispatch({type : "logout"})
        }, 2000);

         
    }



  



    return(
        <SafeAreaView style={styles.navBar}>



            {/* left navbar */}
            <View style={styles.navBarMenu}>
            {
            nav&&nav.map((navTap,index)=>
            <TouchableOpacity 
            key={navTap.id}
            onPress={()=>{

                
                
                    navigation.navigate(navTap.pageLink)


                console.log( navTap.setActive)
            }
            
            }
            
            onMouseEnter={() => setIsHovered(navTap.id)}
      onMouseLeave={() => setIsHovered(0)}
      style={[styles.navBarButton,{backgroundColor:isHovered==navTap.id
        ||active==navTap.id
        ?"#1C5F92":"#81ADD3"}]}          >
            <Image 
                source={navTap.icon}  
                style={styles.logoIcon}
            />
            <Text style={styles.navBarButtonText}>
                {lang==="ar"?navTap.pageNameAr:
                navTap.pageNameEn}
            </Text>
        </TouchableOpacity>

            )
        }
            </View>


            {/* right navbar */}
            <View style={styles.rightNavBar}>
    


            </View>
   



        </SafeAreaView>
    )
  
}


export default NavBar3;
