import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';





const EditItem =({navigation,route})=>{
  const[loading,setloading]=useState(false)
    const { item} = route.params;

  const [category,setcategory] = useState([
    { id: 1, categorAR: "جاري التحميل...", categoryEN: "loading..." },
  ]);
    const nav=useSelector(state =>state.navbar.nav2Active);
    const [categoryId, setCategoryId] = useState(item.category_id);
    const [itemNameAR, setItemNameAR] = useState(item.itemNameAR);
    const [itemNameEN, setItemNameEN] = useState(item.itemNameEN);
    const [descriptionAR, setDescriptionAR] = useState(item.descriptionAR);
    const [descriptionEN, setDescriptionEN] = useState(item.descriptionEN);
    const [cal, setCal] = useState(item.cal);
    const [carb, setCarb] = useState(item.carb);
    const [protein, setProtein] = useState(item.protein);
  
    const handleAddItem = () => {
      setloading(true)
      if (
        !itemNameAR.trim() ||
        !itemNameEN.trim() ||
        !descriptionAR.trim() ||
        !descriptionEN.trim()
      ) {
        alert('Please fill in all fields.');
        return;
      }
      // Do something with the item data, such as sending it to a server
      const data={
        category_id:categoryId,
        itemNameAR:itemNameAR,
        itemNameEN:itemNameEN,
        descriptionAR:descriptionAR,
        descriptionEN:descriptionEN,
        cal:cal,carb:carb,protein:protein

      }
      console.log({
      data
      });
      axios({
        method: "put",
        url: api+"item/"+item.id,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:data
    })
    .then((response) => {
         console.log(response.data);
         setloading(false)
         alert("Update Done")
    })
    .catch((error) => {
        console.log(error);
    });
    };
    const [error, setError] = useState(null);
  
    const getCategoryis=()=>{
      axios({
        method: "get",
        url: api+"category",
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setcategory(response.data)
    })
    .catch((error) => {
        console.log(error);
    });

    }

    useEffect(() => {
      
      setTimeout(function() {getCategoryis()}, 2000);
    }, [])
    
     
    return(
      loading?<Text>loading...</Text>:
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={1}/>
      <NavBar3 navigation={navigation} nav2active={4}/>



        
      <View style={styles.container2}>
        <Text >update Item page</Text>


  






            <View >
            

          
            </View>

          {/* all text input */}
 

{/* item name  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>item name</Text>

{/* two row view */}
<View style={styles.ViewInput2Row}>

     {/* input Item Name (AR) */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Item Name (AR)</Text>
<TextInput
        placeholder="Item Name (AR)"
        value={itemNameAR}
        onChangeText={setItemNameAR}
        style={styles.TextInput}
      />
</View>
 {/* end of input Item Name (AR)*/}


  {/* input Item Name (EN) */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Item Name (EN)</Text>
<TextInput
        placeholder="Item Name (EN)"
        value={itemNameEN}
        onChangeText={setItemNameEN}
        style={styles.TextInput}
      />
</View>
 {/* end of input Item Name (EN) */}

</View>
{/* end of two row view */}
</View>
      {/* end of item name  */}


      {/* Description  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Description</Text>

{/* two row view */}
<View style={styles.ViewInput2Row}>


{/* input Description (AR) */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Description (AR)</Text>
<TextInput
        placeholder="Description (AR)"
        value={descriptionAR}
        onChangeText={setDescriptionAR}
        style={styles.TextInput}
      />
</View>
 {/* end of input Description (AR) */}

 {/* input Description (EN) */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Description (EN)</Text>
<TextInput
        placeholder="Description (EN)"
        value={descriptionEN}
        onChangeText={setDescriptionEN}
        style={styles.TextInput}
      />
</View>
 {/* end of input Description (EN) */}




</View>
   {/* end of two row view */}
</View>
      {/* end of Description  */} 
     
      
      


   {/* Category  */}
   <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Category</Text>
 {/* input Category */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>select a Category</Text>
<Picker
        selectedValue={categoryId}
        onValueChange={(itemValue, itemIndex) => setCategoryId(itemValue)}
        style={styles.TextInput}

      >
        {category.map((c) => (
          <Picker.Item
            key={c.id}
            label={c.categorAR + ' (' + c.categoryEN + ')'}
            value={c.id}
          />
        ))}
      </Picker>
</View>
 {/* end of inputCategory */}
</View>
   {/* end of Category  */}



    {/* meal info  */}
    <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>meal info</Text>

{/* 4 row view */}
<View style={styles.ViewInput4Row}>


{/* input Cal */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Cal </Text>
<Picker selectedValue={cal} onValueChange={setCal}
        style={styles.TextInput}

>
        {Array.from({ length: 1500 }, (_, i) => (
          <Picker.Item key={i} label={'' + (i + 1)} value={i + 1} />
        ))}
      </Picker>
</View>
 {/* end of input Cal */}

 {/* input Carb */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Carb </Text>
<Picker selectedValue={carb} onValueChange={setCarb}
        style={styles.TextInput}

>
        {Array.from({ length: 350 }, (_, i) => (
          <Picker.Item key={i} label={'' + (i + 1)} value={i + 1} />
        ))}
      </Picker>
</View>
 {/* end of input Carb */}


  {/* input Protein */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Protein </Text>
<Picker selectedValue={protein} onValueChange={setProtein}
        style={styles.TextInput}
>
        {Array.from({ length: 350 }, (_, i) => (
          <Picker.Item key={i} label={'' + (i + 1)} value={i + 1} />
        ))}
      </Picker>
</View>
 {/* end of input Protein */}


</View>
{/* end of 4 row view */}



</View>
    {/*end of meal info  */}



          <View>
   
      
     
      
    



     
     
    </View>










       







        
        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
   onPress={handleAddItem}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>update</Text>
                </TouchableOpacity>

            </View>
           
</View>
    )
}

export default EditItem;