import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';



// deliveryTime FOR DR CHEF
const deliveryTime = [
  { id: 1, time: '3 am to 10 am' },
  { id: 2, time: '11 am to 9 pm' },
];
  // ZONES FOR DR CHEF
  const zones = [
    { id: 1, zone: '1',zoneNameAr:"العاصمة 1" ,zoneNameEn:"1 Capital"},
    { id: 2, zone: '2',zoneNameAr:"2 حولي" ,zoneNameEn:"2 Hawally"},
    { id: 3, zone: '3' ,zoneNameAr:"3 الفروانية" ,zoneNameEn:"3 Farwaniya"},
    { id: 4, zone: '4',zoneNameAr:"4 الاحمدي" ,zoneNameEn:"4 Ahmadi" },
    { id: 5, zone: '5' ,zoneNameAr:"5 مبارك الكبير" ,zoneNameEn:"5 Mubarak Al-Kabeer" },
    { id: 6, zone: '6' ,zoneNameAr:"6 الجهراء" ,zoneNameEn:"6 Al Jahra" },
  ];



// calori club
// const deliveryTime = [
//   { id: 1, time: '5 am to 10 am' },
//   { id: 2, time: '11 am to 9 pm' },
// ];
// const zones = [
//   { id: 1, zone: 'AHMEDI' },
//   { id: 2, zone: 'KUWAIT CITY' },
//   { id: 3, zone: 'FARWANIYA' },
//   { id: 4, zone: 'JAHRA' },
// ];

const EditArea =({navigation,route})=>{
    const { item} = route.params;

    const nav=useSelector(state =>state.navbar.nav2Active);
    const lang=useSelector(state =>state.navbar.language);

    const [error, setError] = useState(null);
  

    const [areaNameAR, setAreaNameAR] = useState(item.area_nameAR);
    const [areaNameEN, setAreaNameEN] = useState(item.area_nameEN);
    const [deliveryTimeId, setDeliveryTimeId] = useState(item.deleviryTimeId);
    const [zoneId, setZoneId] = useState(item.zoneId);
      const [hide_area, setHideArea] = useState(item.hide_areas);

    const handleSubmit = () => {
      if (!areaNameAR || !areaNameEN) {
        setError('Area name is required');
        return;
      }

  
      const formData = {
        area_nameAR: areaNameAR,
        area_nameEN:areaNameEN,
        deleviryTimeId:deliveryTimeId,
        zoneId:zoneId,
        hide_areas:hide_area
      };
      axios({
            method: "put",
            url: api+"Area/"+item.id,
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:formData
        })
        .then((response) => {
             console.log(response.data);
             alert("done")
             setError("done")
        })
        .catch((error) => {
          console.log(error);
          setError("server error")
        });
      console.log('Form data:', formData);
      // Make API call to add new area
    };
    
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={4}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
      <View style={styles.container2}>
        <Text >Add Area page</Text>


        

          {/* all text input */}
 

 {/* Areas name  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Areas name</Text>
{/* 2 row input */}
<View style={styles.ViewInput2Row}>

   {/* Area Name (Arabic)*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Area Name (Arabic)</Text>
<TextInput
        value={areaNameAR}
        onChangeText={setAreaNameAR}
        style={styles.TextInput}

      />
</View>
 {/*end of Area Name (Arabic)*/}

    {/* Area Name (English)*/}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Area Name (English)</Text>
<TextInput
        value={areaNameEN}
        onChangeText={setAreaNameEN}
        style={styles.TextInput}
      />
</View>
 {/*end of Area Name (English)*/}

</View>
{/*end of 2 row input */}

</View>
{/* end of Areas name */}


 {/* Delivery info */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Delivery info</Text>
{/* 2 row input */}
<View style={styles.ViewInput2Row}>

     {/* Delivery Time*/}
     <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Delivery Time</Text>
<Picker
        selectedValue={deliveryTimeId}
        onValueChange={(itemValue, itemIndex) => setDeliveryTimeId(itemValue)}
        style={styles.TextInput}
      >
        {deliveryTime.map((item) => (
          <Picker.Item key={item.id} label={item.time} value={item.time} />
        ))}
      </Picker>
</View>
 {/*end of Delivery Time*/}


      {/* Zone*/}
      <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Zone</Text>
<Picker
        selectedValue={zoneId}
        onValueChange={(itemValue, itemIndex) => setZoneId(itemValue)}
        style={styles.TextInput}
      >
        {zones.map((item) => (
          <Picker.Item key={item.id} label={ lang=="ar"?item.zoneNameAr:item.zoneNameEn} value={item.id} />
          ))}
      </Picker>
</View>
 {/*end of Zone*/}


      {/* hide area*/}
      <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>hide area</Text>
<Picker
        selectedValue={hide_area}
        onValueChange={(itemValue, itemIndex) => setHideArea(itemValue)}
        style={styles.TextInput}
      >
        <Picker.Item label="No" value="no" />
        <Picker.Item label="Yes" value="yes" />
      </Picker>
</View>
 {/*end of hide area*/}



</View>
{/*end of 2 row input */}

</View>
{/* end of Delivery info*/}


        </View>
                {/* end of input format */}

      

        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
   onPress={handleSubmit}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>Update</Text>
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default EditArea;