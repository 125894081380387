import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import axios from 'axios';

import useTable from "../../../assets/hooks/useTable";
import { api } from '../../../assets/assets';





const SearchVersions = ({navigation}) => {
    
  const apilink='Version';
  const pagetoEdit='EditVersion'

  const [data,setData]=useState([])
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);



  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);


const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
    })
    .catch((error) => {
        console.log(error);
    });
}

  // Update the table data when the incoming data prop changes
  useEffect(() => {
    axios({
      method: "get",
      url: api+apilink,
      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      },
  })
  .then((response) => {
       console.log(response.data);
       setTableData(response.data);
       setData(response.data);
    setHeaderColumns(Object.keys(response.data[0] || {}));
    setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
  })
  .catch((error) => {
      console.log(error);
  });
    
  }, []);


  const toggleColumn = (header) => {
    if (hiddenColumns.includes(header)) {
      // If the column is hidden, show it
      setHiddenColumns(hiddenColumns.filter((column) => column !== header));
      setHeaderColumns([...headerColumns, header]);
      setTableData(
        tableData.map((item) => {
          return { ...item, [header]: hiddenColumns.includes(header) ? item[header] : null };
        })
      );
    } else {
      // If the column is shown, hide it
      setHiddenColumns([...hiddenColumns, header]);
      setHeaderColumns(headerColumns.filter((column) => column !== header));
      setTableData(
        tableData.map((item) => {
          const { [header]: value, ...rest } = item;
          return rest;
        })
      );
    }
  };


 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  // Handle filtering the table when the search input changes
  const handleSearch = (text) => {setSearch(text); setTableData(TableHandleSearch(text,data));};



// Render each row of the table as a FlatList item
const renderItem = ({ item }) => {
  return (
   

  <View style={styles.row}>
    
   
  {headerColumns.map((header,index) => (

  <Text key={header} style={styles.cell}>
  {item[header]}
  </Text>
  ))}
  <TouchableOpacity 
  onPress={()=>navigation.navigate(pagetoEdit,
  {item:item})}
  >
  <Text style={[styles.cell,{color:"blue"}]}>
           تعديل
           </Text>
          </TouchableOpacity>
  </View>
 
  );
  };

  const renderHeader = () => {
    return (
      <View style={{ flexDirection: "row", backgroundColor: "lightgray" }}>
        {headerColumns.map((header, index) => (
          <Text key={index} style={styles.cell}>
            {header}
          </Text>
        ))}
         <Text  style={styles.cell}>
           تعديل
          </Text>
      </View>
    );
  };
  
  // Calculate the total number of pages based on the number of rows and the max rows per page
  const totalPages = Math.ceil(tableData.length / 20);
  
  // Create an array of page numbers to render page buttons
  const pageNumbers = Array.from(Array(totalPages), (_, i) => i + 1);
  
  // Filter the table data based on the current page
  const startIndex = (currentPage - 1) * 20;
  const endIndex = startIndex + 20;
  const paginatedData = tableData.slice(startIndex, endIndex);
  
  return (
    <SafeAreaView style={[styles.container,{minHeight:1080}]}>
    <NavBar navigation={navigation}/>
    <NavBar2 navigation={navigation} nav2active={5}/>
    <NavBar3 navigation={navigation} nav2active={2}/>



      
<Text style={{color:"white"}}>country page</Text>
        <View style={{justifyContent:"space-between"
    ,width:"100%"
    }}>
  <View style={styles.container}>
 
  <TouchableOpacity style={styles.downloadButton} onPress={()=>refresh()}>
  <Text style={styles.downloadButtonText}>refresh</Text>
  </TouchableOpacity>
 <TouchableOpacity style={styles.downloadButton} onPress={handleDownload}>
  <Text style={styles.downloadButtonText}>Download Table as CSV</Text>
  </TouchableOpacity>
 


  <View style={styles.searchContainer}>
  <TextInput
         style={styles.searchInput}
         placeholder="Search"
         value={search}
         onChangeText={
          handleSearch
        }
       />
  </View>
  <View style={styles.header}>
  {headerColumnsOrginal.map((header) => (
  <TouchableOpacity
  key={header}
  style={styles.headerCell}
  onPress={() => {
   setTableData(handleHeaderClick(header,sortOrder))
   setSortOrder(handlesortedData(header,sortOrder))

    //toggleColumn(header)
   
  }
  
  }
  >
  <Text style={styles.headerText}>#{header}</Text>
  </TouchableOpacity>
  ))}
  </View>


  <ScrollView horizontal={true}>
        <View>
          {renderHeader()}
          <FlatList
            data={tableData}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </ScrollView>
  <View style={styles.pageContainer}>
  {pageNumbers.map((pageNumber) => (
  <TouchableOpacity
  key={pageNumber}
  style={[
  styles.pageButton,
  currentPage === pageNumber && styles.currentPageButton,
  ]}
  onPress={() => setCurrentPage(pageNumber)}
  >
  <Text
  style={[
  styles.pageButtonText,
  currentPage === pageNumber && styles.currentPageButtonText,
  ]}
  >
  {pageNumber}
  </Text>
  </TouchableOpacity>
  ))}
  </View>
  
  </View>
  </View></SafeAreaView>
  );
  };
  
  const styles = StyleSheet.create({
  container: {
  flex: 1,
  backgroundColor: "#1E1E22",
  padding: 10,
  },
  header: {
  flexDirection: "row",
  backgroundColor: "#eee",
  paddingVertical: 5,
  marginBottom:30
  },
  headerCell: {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  },
  headerText: {
  fontWeight: "bold",
  },
  row: {
  
  flexDirection: "row",
  borderBottomWidth: 1,
  borderBottomColor: "#ccc",
  
  },
  cell: {
  flex: 1,
  alignItems: "center",
  textAlign:"center",
  justifyContent: "center",
  width:200,marginBottom:5,
  padding:10,
  backgroundColor:"#A9A7AB"
  },
  searchContainer: {
  flexDirection: "row",
  marginVertical: 10,
  },
  searchInput: {
  flex: 1,
  borderWidth: 1,
  borderColor: "#ccc",
  borderRadius: 5,
  paddingHorizontal: 10,
  paddingVertical:10,
  color:"white",
  fontSize: 16,
  },
  pageContainer: {
  flexDirection: "row",
  justifyContent: "center",
  marginVertical: 10,
  },
  pageButton: {
  borderWidth: 1,
  borderColor: "#ccc",
  borderRadius: 5,
  paddingVertical: 5,
  paddingHorizontal: 10,
  marginHorizontal:5,
},
currentPageButton: {
backgroundColor: "#A9A7AB",
},
pageButtonText: {
fontSize: 16,
},
currentPageButtonText: {
color: "#fff",
},
downloadButton: {
backgroundColor: "#A9A7AB",
paddingVertical: 10,
width:300,
borderRadius:10,
alignItems: "center",
marginTop: 20,
},
downloadButtonText: {
color: "#fff",
fontWeight: "bold",
fontSize: 16,
},
});

export default SearchVersions;