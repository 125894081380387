const colors={
    backgroundblack:"#141414",
    backgroundGray:"#1E1E22",
    borderGray:"#111111",
    inputbg:"#181818",
    grayFont:"#A9A7AB",
    offbtn:"#39393C",
    btnGray:"#1A1A1D",
    lightBlueBtn:"#1900D5",
    white:"#FFFFFF"
    }
    export default colors;