import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';





const AddDiscountCode =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);
    const [error,seterror]=useState('')
    const [code, setCode] = useState('');
    const [minimumPrice, setMinimumPrice] = useState(1);
    const [count, setCount] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [amount, setAmount] = useState(0);
  
    const handlePercentageChange = (value) => {
      setPercentage(parseInt(value, 10));
      setAmount(0);
    };
  
    const handleAmountChange = (value) => {
      setAmount(parseInt(value, 10));
      setPercentage(0);
    };
  
    const handleSubmit = () => {
        if (code && minimumPrice && !isNaN(count) && (percentage || amount)) {
            // submit discount code data
                    const data={
                        code:code,
                        minimumPrice:minimumPrice,
                        count:count,
                        percentage:percentage,
                        amount:amount
                    }
        console.log({
       data
        });
        axios({
            method: "post",
            url: api+"DiscountCode",
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:data
        })
        .then((response) => {
             console.log(response.data);
             alert("done")
             seterror('')
        })
        .catch((error) => {
            console.log(error);
        });
        
      }else{
        if(isNaN(count)){
            seterror("count is anumber only")

        }else{
            seterror("please put all data ")

        }
      }
    };
    useEffect(() => {
      
    //   setTimeout(function() {getCategoryis()}, 2000);
    }, [])
    
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={5}/>
      <NavBar3 navigation={navigation} nav2active={1}/>



        
      <View style={styles.container2}>
        <Text >Add Discount Code page</Text>



          {/* all text input */}


  {/* Discount Code  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Code</Text>

   {/* input Discount Code */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Discount Code</Text>
<TextInput value={code} onChangeText={setCode}   style={styles.TextInput}/>
</View>
 {/* end of input Discount Code */}
</View>
  {/* end of Discount Code  */}


  {/* price Code  */}
  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>price</Text>


{/* 4 row view */}
<View style={styles.ViewInput4Row}>

   {/* input Minimum Price */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Minimum Price</Text>
<Picker selectedValue={minimumPrice} onValueChange={setMinimumPrice} 
        style={styles.TextInput}
>
        {Array.from({ length: 300 }, (_, i) => i + 1).map((value) => (
          <Picker.Item key={value} label={value.toString()} value={value} />
        ))}
      </Picker></View>
 {/* end of input Minimum Price */}

    {/* input count */}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>how many time use</Text>
<TextInput
        value={count}
        onChangeText={setCount}
        keyboardType="numeric"
        style={styles.TextInput}
      />
      </View>
 {/* end of count */}

     {/* input Percentage */}
     <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>by Percentage :</Text>
<Picker selectedValue={percentage} onValueChange={handlePercentageChange}
        style={styles.TextInput}
>
        {Array.from({ length: 301 }, (_, i) => i).map((value) => (
          <Picker.Item key={value} label={value.toString()} value={value} />
        ))}
      </Picker>
      </View>
 {/* end of Percentage */}

 

</View>
{/*end of 4 row view */}
  {/* input by Amount */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>by Amount: :</Text>
<Picker selectedValue={amount} onValueChange={handleAmountChange}
        style={styles.TextInput}
>
        {Array.from({ length: 301 }, (_, i) => i).map((value) => (
          <Picker.Item key={value} label={value.toString()} value={value} />
        ))}
      </Picker>
      </View>
 {/* end of by Amount */}


</View>
  {/*  end of price Code  */}


          <View>
      

  

      
     

    

    </View>





      










          







        
        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
   onPress={handleSubmit}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default AddDiscountCode;