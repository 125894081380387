import React,{useState} from 'react'
import { TouchableOpacity,View,Text } from 'react-native'
import {useDispatch,useSelector} from "react-redux";

const InprogressBtn=()=>{
    const dispatch = useDispatch();
    const [states,setstates]=useState('in progress')
const setDone=()=>{
    if(states == "in progress")
    {
        dispatch({type : "remainin"})
        setstates("done")
    }
    else{
        dispatch({type : "addtoremainin"})
        setstates("in progress")
    }
   


    

}
    return(
        <TouchableOpacity
        onPress={()=> {
            setDone()

    }
    }
        >
            <View style={{width:100,height:30,backgroundColor:states=="done"?"green":"red"}}>
            <Text>
               {states}
            </Text>
            </View>
            
        </TouchableOpacity>
    )
}
export default InprogressBtn;