import axios from "axios";
import { api } from "../assets";

const useaxios =()=>{
    const getaxios=(apilink)=>{
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: api+apilink,
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '
                },
              })
              .then(response => {
                console.log("im here")
                console.log(response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error.message);
              });
        });
    }

    return[getaxios]

}

export default useaxios
