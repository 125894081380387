import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';





const AddUser =({navigation})=>{
  const [loading, setLoading] = useState(false);
  const [areas,setareas] = useState([
    { area_nameAR: 'loading', id: 1 }
  ]);
    const nav=useSelector(state =>state.navbar.nav2Active);

    // error 
      const [error, setError] = useState(null);
  
    //User Data States
      const [name, setName] = useState('');
      const [email, setEmail] = useState('thegreenery@hotmail.com');
      const [phone, setPhone] = useState('');
      const [password, setPassword] = useState('');
      const [passwordAgain, setPasswordAgain] = useState('');
      const [receptPhone, setReceptPhone] = useState('');
      const [weight, setWeight] = useState(40);
      const [height, setHeight] = useState(40);
      const [gender, setGender] = useState('female');
  

      const [areaId, setAreaId] = useState(areas[0].area_id);
      const [block,setblock]=useState(0);

      const [street, setStreet] = useState('0');
      const [jadah, setJadah] = useState('0');
      const [home, setHome] = useState('');
      const [floor, setFloor] = useState('0');
      const [flat, setFlat] = useState('0');
      const [formError, setFormError] = useState(false);

   // select date 
   const [showBirthDatePicker, setshowBirthDatePicker] = useState(false);
   const [birthdayPiker, setBirthdayPiker] = useState(new Date());
   const [birthday, setBirthday] = useState('2023-03-03');
 
   const handleBirthDateChange = (date) => {
    setBirthdayPiker(date);
    setshowBirthDatePicker(false);
     const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, "0");
     const day = String(date.getDate()).padStart(2, "0");
     setBirthday(`${year}-${month}-${day}`)
   }


      // const handleDateChange = (date) => {
      //   setBirthdayPiker(date);
      //   // setShowDatePicker(false);
      //   const year = date.getFullYear();
      //   const month = String(date.getMonth() + 1).padStart(2, "0");
      //   const day = String(date.getDate()).padStart(2, "0");
      //   setBirthday(`${year}-${month}-${day}`)
      // }  
      const handleSubmit = () => {
        if (
          name !== '' &&
          phone.length === 8 &&
          password.length >= 8 &&
          password === passwordAgain &&
          receptPhone.length === 8 &&
          street !== '' &&
          home !== ''
        ) {
          console.log('Form is valid. Submitting...');
          // TODO: Add logic to submit the form data
        } else {
          setFormError(true);
        }
      };




      const handleAddUser = () => {
        setLoading(true)
        if (isValidUser()) {
          const newUser = {
            name: name,
            email: email,
            phone:phone,
            password:password,
            phoneOfRecept:receptPhone,
            wieght:weight,
            hight:height,
            gender:gender,
            birthday:birthday,
            area_id:areaId,
            block:block,
            street:street,
            jadah:jadah,
            home:home,
            floor:floor,
            flat:flat,
          };
          //console.log(newUser);
          axios({
            method: "post",
            url: api+"User",
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:newUser
        })
        .then((response) => {
             console.log(response.data);
             alert("add done")
             setError("done")
             setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response.data)
          console.log(error.response.data.data['phone']=="The phone has already been taken."?setError("The phone has already been taken."):setError("error server"));
        });
        } else {
          console.log('Invalid user!' + isValidUser() );
        }
      };
    
      const isValidEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      
        return emailRegex.test(email);
      }

      const isValidUser = () => {
        if (
          name.trim() !== '' &&
          (email === '' || isValidEmail(email)) &&
          phone.length === 8 &&
          receptPhone.length === 8 &&
          password.length >= 8 &&
          password === passwordAgain &&
          weight !== '' &&
          height !== '' &&
          gender !== '' &&
          street.trim() !== '' &&
          areaId !== '' &&
          home.trim() !== ''
        ) {
          setError('')
          return true;
        } else {
          let message = 'Invalid user: ';
          setError(message)
          if ( name.trim() === '') {
            setError( message += 'name is empty. ');
          }
          else if ( phone.length != 8) {
            setError( message += ' phone must be 8 digit');
          }
          else if ( receptPhone.length != 8) {
            setError( message += ' receptPhone must be 8 digit');
          }
          else if (gender === '') {
            setError( message += 'gender is empty. ');
          }
          else if (email !== '' && !isValidEmail(email)) {
            setError(message += 'email is invalid. ');
          }
          else if ( home.trim() === '') {
            setError( message += ' home is empty. ');
          }
          else if ( areaId === '') {
            setError( message += 'please select area');
          }
          else if (password.length < 8 || passwordAgain.length < 8){
            setError( message += ' password less than 8 digit ');

          }
          else if ( password != passwordAgain) {
            setError( message += ' two password not matching ');
          }
          else{
            setError( message += 'Do not leave any entry blank but 0 if not value like jadah = 0 or flat =0 or floor =0');

          }
          
          return false;
        }
      };

      useEffect(() => {
        axios({
          method: "get",
          url: api+'Area',
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          }
      })
      .then((response) => {
           console.log(response.data);
           setareas(response.data)
           setAreaId(response.data[0].id)
      })
      .catch((error) => {
          console.log(error);
      });
      }, [])
    return(
      loading ? <Text>Loading...</Text> :
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={1}/>
      <NavBar3 navigation={navigation} nav2active={1}/>



        
        <View style={styles.container2}>
        <Text >Add User page</Text>

         

          {/* all text input */}
          <View>


{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>genral info</Text>


      {/* 4 row input */}
<View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

 {/* input Name */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name*</Text>
<TextInput
  value={name}
  onChangeText={setName}
  placeholder="Enter your name"
  style={styles.TextInput}

/>
</View>
{/* end of input Name */}

  {/* input email */}
  {/* <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>Email</Text>
      <TextInput
        value={email}
        onChangeText={setEmail}
        placeholder="Enter your email"
        keyboardType="email-address"
        style={styles.TextInput}

      />
      </View> */}
      {/* end of input email */}

        {/* input Phone */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>Phone*</Text>
      <TextInput
        value={phone}
        onChangeText={setPhone}
        placeholder="Enter your phone number"
        keyboardType="numeric"
        maxLength={8}
        style={styles.TextInput}

      />
         </View>
      {/* end of input phone */}


      {/* input Recept Phone */}
      <View style={styles.inputView}>
        <Text style={styles.inputHeaderText}>Phone of Recept*</Text>
      <TextInput
        value={receptPhone}
        onChangeText={setReceptPhone}
        placeholder="Enter phone of the recipient"
        keyboardType="numeric"
        maxLength={8}
        style={styles.TextInput}

      />
        </View>
            {/* end of Recept phone */}

        </View>
      </View>
      {/* end of genral info  */}


{/* Password info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Password</Text>

 {/* first password */}
 <View style={styles.inputView}>
 <Text style={styles.inputHeaderText}>Password*</Text>
      <TextInput
        value={password}
        onChangeText={setPassword}
        placeholder="Enter your password"
        secureTextEntry
        style={styles.TextInput}

      />
 </View>
  {/* end first password */}


 {/* secound password */}
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Password Again*</Text>
      <TextInput
        value={passwordAgain}
        onChangeText={setPasswordAgain}
        placeholder="Enter your password again"
        secureTextEntry
        style={styles.TextInput}

      />
  </View>
 {/* end of secound password */}


</View>
 {/* end of password input View */}



{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>body info</Text>

 {/* 4 row input */}
 <View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

  {/* input Weight */}
  <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Weight</Text>
      <Picker
        selectedValue={weight}
        onValueChange={(value) => setWeight(value)}
        style={styles.TextInput}

      >
        {Array.from({ length: 261 - 39 }, (_, i) => i + 40).map((value) => (
          <Picker.Item key={value} label={`${value}kg`} value={value} />
        ))}
      </Picker>
  </View>
    {/* end of input Weight */}


 {/* input Height */}
 <View style={styles.inputView}>
    <Text style={styles.inputHeaderText}>Height</Text>
      <Picker
        selectedValue={height}
        onValueChange={(value) => setHeight(value)}
        style={styles.TextInput}
      >
        {Array.from({ length: 261 }, (_, i) => (
<Picker.Item key={i} label={`${i + 40} cm`} value={`${i + 40}`} />
))}
</Picker>
</View>
    {/* end of input Height */}


     {/* input Gender */}
 <View style={styles.inputView}>
 <Text style={styles.inputHeaderText}>Gender</Text>
<Picker
selectedValue={gender}
onValueChange={(value) => setGender(value)}
style={styles.TextInput}

>
<Picker.Item label="Female" value="female" />
<Picker.Item label="Male" value="male" />
</Picker>
 </View>
 {/* end of input Height */}



  </View>
  {/* input Birthday */}
<View style={styles.inputView}>
 <Text style={styles.inputHeaderText}>Birthday</Text>

 {!showBirthDatePicker&& 
<TouchableOpacity onPress={() => setshowBirthDatePicker(true)}>
<View   style={styles.TextInput}>
    <Text > {birthday}</Text>
    </View>
</TouchableOpacity>}

{showBirthDatePicker && (
  <View  style={datePickerStyles.container}>
<DateTimePicker

value={birthdayPiker}
onChange={handleBirthDateChange}
format="yyyy-MM-dd"
style={datePickerStyles.container}
/></View>)}

</View>
 {/* end of input Birthday */}
</View>

      
{/* address  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>address</Text>

 {/* 4 row input */}
 <View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>


{/* input Area */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Area</Text>
<Picker
selectedValue={areaId}
onValueChange={(value) => setAreaId(value)}
style={styles.TextInput}


>
{areas.map((area) => (
<Picker.Item
key={area.id}
label={area.area_nameAR}
value={area.id}
/>
))}
</Picker>
</View>
{/* end of input Area */}


{/* input block */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>block</Text>
<TextInput
style={styles.TextInput}
onChangeText={(text) => setblock(text)}
value={block}
/>
</View>
{/* end of input block */}

{/* input Street */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Street</Text>
<TextInput
style={styles.TextInput}
onChangeText={(text) => setStreet(text)}
value={street}
/>
</View>
{/* end of input Street */}

{/* input Jadah */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Jadah</Text>
<TextInput
style={styles.TextInput}
onChangeText={(text) => setJadah(text)}
value={jadah}
/>
</View>
{/* end of input Jadah */}

{/* input Home */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Home</Text>
<TextInput
style={styles.TextInput}
onChangeText={(text) => setHome(text)}
value={home}
/>
</View>
{/* end of input Home */}


 </View>
 {/* end of 4 rows */}

{/* input Flat */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Flat</Text>
<TextInput
style={styles.TextInput}
onChangeText={(text) => setFlat(text)}
value={flat}
/>
</View>
{/* end of input Floor */}



{/* input Floor */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Floor</Text>
<TextInput
style={styles.TextInput}
onChangeText={(text) => setFloor(text)}
value={floor}
/>
</View>
{/* end of input Floor */}


 </View>
 {/* end of address  */}


</View>
        </View>
        
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={()=>handleAddUser()}
     disabled={!isValidUser}
>
<Text style={styles.saveBtnText}>add</Text>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default AddUser;
