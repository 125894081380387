import React from "react";
import { View,Text } from "react-native";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import Kitchin from "../pages/kitchin/Kitchin";
import NavBar from "../pages/navbar/NavBar";
import {useDispatch,useSelector} from "react-redux";
import Login from "../pages/login/Login";
import Country from "../pages/region/country/Country";
import Citys from "../pages/region/Citys";
import Main from "../pages/Main";
import AddCountry from "../pages/region/country/AddCountry";
import SearchCountry from "../pages/region/country/SearchCountry";
import EditCountry from "../pages/region/country/EditCountry";
import AddCompany from "../pages/company/company/AddCompany";
import AddTableMeals from "../pages/kitchin/mealsTable/AddTableMeals";
import AddUser from "../pages/dataentry/users/AddUser";
import AddSubscribe from "../pages/dataentry/subscribe/AddSubscribe";
import SearchUser from "../pages/dataentry/users/SearchUser";
import EditUser from "../pages/dataentry/users/EditeUser";
import AddPackage from "../pages/dataentry/package/AddPackge";
import SearchPackages from "../pages/dataentry/package/SearchPackages";
import EditPackage from "../pages/dataentry/package/EditPackage";
import AddPackagePrice from "../pages/dataentry/package/AddPackagePrice";
import AddArea from "../pages/dataentry/area/AddArea";
import SearchAreas from "../pages/dataentry/area/SearchAreas";
import EditArea from "../pages/dataentry/area/EditArea";
import AddVersion from "../pages/dataentry/version/AddVersion";
import SearchVersions from "../pages/dataentry/version/SearchVersions";
import EditVersion from "../pages/dataentry/version/EditVersion";
import AddItemsImg from "../pages/kitchin/items/AddItemsImg";
import Table from "../pages/table/Table";
import MealsAnalysis from "../pages/kitchin/mealsTable/MealsAnalysis";
import MealsDetails from "../pages/kitchin/mealsTable/MealsDetails";
import AddItems from "../pages/kitchin/items/AddItems";
import SearchItems from "../pages/kitchin/items/SearchItems";
import EditItem from "../pages/kitchin/items/EditItem";
import AddCategory from "../pages/kitchin/category/AddCategory";
import SearchCategory from "../pages/kitchin/category/SearchCategory";
import EditCategory from "../pages/kitchin/category/EditCategory";
import SearchPrice from "../pages/dataentry/package/SearchPrice";
import EditPrice from "../pages/dataentry/package/EditPrice";
import AddDiscountCode from "../pages/dataentry/discountCode/AddDiscountCode";
import SearchDiscountCodes from "../pages/dataentry/discountCode/SearchDiscountCodes";
import EditDiscountCode from "../pages/dataentry/discountCode/EditDiscountCode";
import AddMealsNotSelect from "../pages/kitchin/mealsTable/AddMealsNotSelect";
import Deleviry from "../pages/kitchin/deleviry/Deleviry";
import PrintMeals from "../pages/kitchin/print/PrintMeals";
import SearchSubscribe from "../pages/dataentry/subscribe/SearchSubscribe";
import EditSubscribe from "../pages/dataentry/subscribe/EditSubscribe";
import SearchImgItem from "../pages/kitchin/items/SearchImgItem";
import EditImgItem from "../pages/kitchin/items/EditImgItem";
import AddCategoryChef from "../pages/kitchin/category_chef/AddCategoryChef";
import SearchCategoryChef from "../pages/kitchin/category_chef/SearchCategoryChef";
import EditCategoryChef from "../pages/kitchin/category_chef/EditCategoryChef";
import AddTableNotSelect from "../pages/kitchin/mealsTable/AddTableNotSelect";


const Stack = createNativeStackNavigator();



  const Navigation =()=>{

    const nav=useSelector(state =>state.navbar.nav);
    const login=useSelector(state => state.login.loginState)

    const HomeStack=()=>{
      return(
     <NavigationContainer>
        <Stack.Navigator >


           {/* country */}
           <Stack.Screen name="Main" component={Main} 
                    options={{
                      headerShown: false,
                    }}
                    />





          {/* country */}
            <Stack.Screen name="Country" component={Country} 
                    options={{
                      headerShown: false,
                    }}
                    />

<Stack.Screen name="AddCountry" component={AddCountry} 
                    options={{
                      headerShown: false,
                    }}
                    />
<Stack.Screen name="SearchCountry" component={SearchCountry} 
                    options={{
                      headerShown: false,
                    }}
                    />

<Stack.Screen name="EditCountry" component={EditCountry} 
                    options={{
                      headerShown: false,
                    }}
                    />




        <Stack.Screen name="Citys" component={Citys} 
                    options={{
                      headerShown: false,
                    }}
                    />



{/* customers */}
<Stack.Screen name="AddCompany" component={AddCompany} 
                              options={{ headerShown: false}}
                        />




          {/* users */}
                      <Stack.Screen name="AddUser" component={AddUser} 
                                options={{ headerShown: false}}

                        />
                        <Stack.Screen name="SearchUser" component={SearchUser} 
                                options={{ headerShown: false}}

                        />
                        <Stack.Screen name="EditUser" component={EditUser} 
                                options={{ headerShown: false}}

                        />


                        {/* items */}
                        <Stack.Screen name="AddItemsImg" component={AddItemsImg} 
                                options={{ headerShown: false}}
                        />
                        <Stack.Screen name="SearchImgItem" component={SearchImgItem} 
                                options={{ headerShown: false}}
                        />
                         <Stack.Screen name="EditImgItem" component={EditImgItem} 
                                options={{ headerShown: false}}
                        />
                        

                         <Stack.Screen name="AddItems" component={AddItems} 
                                options={{ headerShown: false}}

                        />
                         <Stack.Screen name="SearchItems" component={SearchItems} 
                                options={{ headerShown: false}}

                        />
                         <Stack.Screen name="EditItem" component={EditItem} 
                                options={{ headerShown: false}}

                        />


                        {/* category */}
            
                        <Stack.Screen name="AddCategory" component={AddCategory} 
                                options={{ headerShown: false}}
                        />
                         <Stack.Screen name="SearchCategory" component={SearchCategory} 
                                options={{ headerShown: false}}
                        />
                         <Stack.Screen name="EditCategory" component={EditCategory} 
                                options={{ headerShown: false}}
                        />


                        {/* chef category  */}
                        
                        <Stack.Screen name="AddCategoryChef" component={AddCategoryChef} 
                                options={{ headerShown: false}}
                        />
                         <Stack.Screen name="SearchCategoryChef" component={SearchCategoryChef} 
                                options={{ headerShown: false}}
                        />
                        <Stack.Screen name="EditCategoryChef" component={EditCategoryChef} 
                                options={{ headerShown: false}}
                        />


                           {/* Table not select  */}
                        
                           <Stack.Screen name="AddTableNotSelect" component={AddTableNotSelect} 
                                options={{ headerShown: false}}
                        />
                   
                        

                        
                        
                        
                        
                        

          {/* subscribe */}
          <Stack.Screen name="AddSubscribe" component={AddSubscribe} 
                                options={{ headerShown: false}}
                        />
        <Stack.Screen name="SearchSubscribe" component={SearchSubscribe} 
                                options={{ headerShown: false}}
                        />
        <Stack.Screen name="EditSubscribe" component={EditSubscribe} 
                options={{ headerShown: false}}
        />





          {/* packages */}
          <Stack.Screen name="AddPackage" component={AddPackage} 
                                options={{ headerShown: false}}

                        />
          <Stack.Screen name="SearchPackages" component={SearchPackages} 
                                options={{ headerShown: false}}

                        />
            <Stack.Screen name="EditPackage" component={EditPackage} 
                                options={{ headerShown: false}}

                        />
<Stack.Screen name="AddPackagePrice" component={AddPackagePrice} 
                                options={{ headerShown: false}}
                        />
                        <Stack.Screen name="SearchPrice" component={SearchPrice} 
                                options={{ headerShown: false}}
                        />
                         <Stack.Screen name="EditPrice" component={EditPrice} 
                                options={{ headerShown: false}}
                        />
                        
                        


                      {/* area */}
                      <Stack.Screen name="AddArea" component={AddArea} 
                                options={{ headerShown: false}}

                        />
                         <Stack.Screen name="SearchAreas" component={SearchAreas} 
                                options={{ headerShown: false}}

                        />
                         <Stack.Screen name="EditArea" component={EditArea} 
                                options={{ headerShown: false}}

                        />


                        {/* versions */}
                        <Stack.Screen name="AddVersion" component={AddVersion} 
                                options={{ headerShown: false}}

                        />
                          <Stack.Screen name="SearchVersions" component={SearchVersions} 
                                options={{ headerShown: false}}

                        />
                         <Stack.Screen name="EditVersion" component={EditVersion} 
                                options={{ headerShown: false}}

                        />




                                        {/* discount codes */}
                                        <Stack.Screen name="AddDiscountCode" component={AddDiscountCode} 
                                options={{ headerShown: false}}
                        />
                                <Stack.Screen name="SearchDiscountCodes" component={SearchDiscountCodes} 
                                options={{ headerShown: false}}
                        />
                           <Stack.Screen name="EditDiscountCode" component={EditDiscountCode} 
                                options={{ headerShown: false}}
                        />
                        
                                        


                                {/* print */}
                                <Stack.Screen name="PrintMeals" component={PrintMeals} 
                                options={{ headerShown: false}}
                        />
                                

          


          {/* mealsTable */}
          <Stack.Screen name="AddTableMeals" component={AddTableMeals} 
                                options={{ headerShown: false}}

                        />
          <Stack.Screen name="MealsAnalysis" component={MealsAnalysis} 
                                options={{ headerShown: false}}

                        />
          <Stack.Screen name="MealsDetails" component={MealsDetails} 
                                options={{ headerShown: false}}

                        />
                <Stack.Screen name="AddMealsNotSelect" component={AddMealsNotSelect} 
                                options={{ headerShown: false}}

                        />




{/* Deleviry */}
<Stack.Screen name="Deleviry" component={Deleviry} 
                                options={{ headerShown: false}}

                        />





            {/* Table */}
            
            <Stack.Screen name="Table" component={Table} 
                                options={{ headerShown: false}}

                        />
          
          
        </Stack.Navigator>
     </NavigationContainer>
      )}



    const LoginStack=()=>{
      return(
      <NavigationContainer>
        <Stack.Navigator >

                      <Stack.Screen name="Login" component={Login} 
                                  options={{ headerShown: false}}
                        />

        </Stack.Navigator>
     </NavigationContainer>
      )
    }









      return (
       login==="false"?
        <LoginStack/>:
        <HomeStack/>
    )

  }

  export default Navigation;