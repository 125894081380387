const useTable=(tableData)=>{
  const TableHandleSearch = (text,data) => {
      let filteredData = data.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(text.toLowerCase());
          } else if (typeof value === "number") {
            return value.toString().toLowerCase().includes(text.toLowerCase());
          } else {
            return false;
          }
        });
      });
      return(filteredData);
    };


// Handle sorting the table when a header cell is clicked
    const handleHeaderClick = (header,sortOrder) => {
      let sortedData = [...tableData];
      if (sortOrder && sortOrder.column === header) {
        // Reverse the sort order if the same column header is clicked again
        // sortedData.reverse();

        sortedData.sort((a, b) => {
          if (a[header] < b[header]) {
            return sortOrder && sortOrder.order === "desc" ? 1 : -1;
          } else if (a[header] > b[header]) {
            return sortOrder && sortOrder.order === "desc" ? -1 : 1;
          }
          return 0;
        });
          //    // setSortOrder({ column: header, order: sortOrder.order === "asc" ? "desc" : "asc" });

      } 
      console.log(sortOrder.column===header)
      return(sortedData);
    };

    function sortArrayByHeader(header, array) {
      const sortedArray = [...array].sort((a, b) => {
        // compare the values of the two objects based on the header
        if (a[header] < b[header]) {
          return -1;
        }
        if (a[header] > b[header]) {
          return 1;
        }
        return 0;
      });
      console.log(array)
      return sortedArray;
    }




// Handle sorting the table when a header cell is clicked
const handlesortedData = (header,sortOrder) => {
  if (sortOrder && sortOrder.column == header) {
    // Reverse the sort order if the same column header is clicked again
   // console.log(sortOrder)

      return({ column: header, order: sortOrder.order == "asc" ? "desc" : "asc" });} 
  else { 
    //console.log(sortOrder)
    return({ column: header, order: "asc" }) }
};



  // Handle downloading the filtered table data as a CSV file
  const handleDownload = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
   let headerColumns= Object.keys(tableData[0] || {})
    // Add the header row to the CSV content
    csvContent += headerColumns.join(",") + "\r\n";

    // Add each data row to the CSV content
    tableData.forEach((item) => {
      let row = Object.values(item).map((value) => {
        if (typeof value === "string") {
          // Escape any commas within the string value
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      });
      csvContent += row.join(",") + "\r\n";
    });

    // Create a temporary link element to trigger the download of the CSV file
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
  };

    return[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload,sortArrayByHeader]
}

export default useTable;