import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/stylesDark/AddCountryStyles'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';

const EditVersion =({navigation,route})=>{
    const { item} = route.params;

    const nav=useSelector(state =>state.navbar.nav2Active);

    const [error, setError] = useState(null);

    const [versionNumber, setVersionNumber] = useState(item.version_number);
    const [deviceName, setDeviceName] = useState(item.device_name);
    const [isActive, setIsActive] = useState(item.is_active==1?"yes":"no");
  
    const handleSubmit = () => {
      if (!versionNumber.trim()) {
        setError('Please enter version number');
        return;
      }
  
      else if (!deviceName.trim()) {
        setError('Please enter device name');
        return;
      }
      else{
        const formData = {
            version_number: versionNumber,
            device_name:deviceName,
            is_active: isActive === 'yes' ? 1 : 0,
          };
      
          console.log('Form data:', formData);
          axios({
            method: 'put',
            url: api+'Version/'+item.id,
            headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
            },
            data:formData
          })
          .then(response => {
            console.log("yes")
            console.log(response.data);
           alert("done")
           setError("done")

          })
          .catch(error => {
            setError(error.message);
  
          });
      }
  
      // You can do any additional validation checks here
  
    
    };
  
    
     
    return(
        <SafeAreaView style={[styles.container,{minHeight:1080}]}>
        <NavBar navigation={navigation}/>
        <NavBar2 navigation={navigation} nav2active={1}/>
        <NavBar3 navigation={navigation} nav2active={1}/>
  
  
  
          
  <Text style={{color:"white"}}>Add versions package page</Text>
            <View style={{justifyContent:"space-between"
        ,width:"100%"
        }}>
          <View style={styles.container}>
  
            
  
            {/* all text input */}
   
  
  
  
  
            <View>
        <Text style={styles.inputHeaderText}>Version</Text>
        <TextInput
          placeholder="Version Number"
          value={versionNumber}
          onChangeText={setVersionNumber}
          style={styles.TextInputTwoRow}
  
        />
              <Text style={styles.inputHeaderText}>Device Name</Text>
  
        <TextInput
          placeholder="Device Name"
          value={deviceName}
          onChangeText={setDeviceName}
          style={styles.TextInputTwoRow}
  
        />
  
  
      <Text style={styles.inputHeaderText}>is hide</Text>
  
        <Picker
          selectedValue={isActive}
          onValueChange={(value) => setIsActive(value)}
          style={styles.TextInputTwoRow}
  
        >
          <Picker.Item label="Yes" value="yes" />
          <Picker.Item label="No" value="no" />
        </Picker>
     
      </View>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
              <View style={styles.saveView}>
              {error && <Text style={{ color: 'red' }}>{error}</Text>}
  
                  <TouchableOpacity style={styles.btnSave}       
     onPress={handleSubmit}
  >
                  <Image 
                  source={saveimg}  
                  style={{width: 19, height: 19 ,margin:5}}
              />
                      <Text style={styles.saveBtnText}>add</Text>
                  </TouchableOpacity>
  
              </View>
  
  
  
  
  
  
  
          </View>
          </View>
  
             
  </SafeAreaView>
    )
}

export default EditVersion;