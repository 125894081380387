import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image
    } from 'react-native'
    import NavBar from './navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../assets/colors/colors';
import NavBar2 from './navbar/NavBar2';
import NavBar3 from './navbar/NavBar3';

import {styles} from '../assets/styleSAP/formaInputSap'
import Table from './table/Table';
import TableShow from './table/TableShow';
import axios from 'axios';
import { api } from '../assets/assets';

const Main =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);

const [data,setData]=useState([])
const [tableData, setTableData] = useState([]);
const [headerColumns, setHeaderColumns] = useState([]);
const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);

const hide=[{header :"id"},{header :"created_at"},{header :"updated_at"}]

const[loading,setloading]=useState(0)

      useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();

        axios({
          method: "get",
          url: api+'Package',
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          },
      })
      .then((response) => {
           console.log(response.data);
           setTableData(response.data);
           setData(response.data);
        setHeaderColumns(Object.keys(response.data[0] || {}));
        setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
        setloading(1)
      })
      .catch((error) => {
          console.log(error);
      });
        
      }, [])
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={0}/>
      <NavBar3 navigation={navigation}/>



        
      <View style={styles.container2}>
        
        <Text >main page</Text>

     


     {/* <Table item={item} navigation={navigation}/> */}

{/* {loading==0?null: <TableShow 
     pagetoEdit1={"EditPackage"}
     data1={data}
     tableData1={tableData}
     headerColumns1={headerColumns}
     headerColumnsOrginal1={headerColumnsOrginal}
     hide={hide}
     navigation={navigation}/>} */}
    




        </View>

           
</View>
    )
}

export default Main;
