import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colorsSAP';
const { width, height } = Dimensions.get('window');

const Poppins_Medium = require('../fonts/Poppins-SemiBold.ttf');
const AlmaraiـBold = require('../fonts/Almarai/Almarai-Bold.ttf');


export const styles = StyleSheet.create({

    navBar:{
        height:35,
        width:"100%",
        backgroundColor:colors.bbgLightWhite,
      alignItems:"flex-end",
        flexDirection:"row",
        paddingHorizontal:15,
        justifyContent:"space-between",
        borderWidth:0
    },
    navBarMenu:{
flexDirection:"row",        

    },

    rightNavBar:{
        flexDirection:"row-reverse"
    },




    navBarButton:{
       
        alignItems:"center",flexDirection:"row",
        padding:5,
        paddingHorizontal:15,
        height:29, 
        backgroundColor:colors.bgLightBlue,
        borderTopLeftRadius:50
        
      
    },
    navBarButtonLang:{
        margin:(height*0.02)/2,
        alignItems:"center",flexDirection:"row",
        padding:height*0.004,
        height:height*0.02, 
    },
    navBarButtonText:{
        fontFamily:Poppins_Medium,
        fontSize:16,
        color:"black",
    },
    logoIcon:{width: 24, height: 24 ,marginRight:10 }
})