const initalState = {
   theme:"light"
};
const theme=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){
case "theDark":
    return{...state,
       theme:state.theme==="dark"?"light":"dark"
    }


  
    
            

}



return state;
}
export default theme;