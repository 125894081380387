import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colors';

const { width, height } = Dimensions.get('window');

const Poppins_Medium = require('../fonts/Poppins-SemiBold.ttf');
const AlmaraiـBold = require('../fonts/Almarai/Almarai-Bold.ttf');



export const styles = StyleSheet.create({
    container: {
        width:"100%",backgroundColor:"#3C3C3C",
            padding:30,borderRadius:5
      },

      TwoRowInputView:{
        flexDirection:"row",alignItems:"center"
      },
      inputHeaderText:{color:colors.white,fontSize:17 ,margin:5,marginTop:20},

      TextInputTwoRow:{width:'30%',padding:10,backgroundColor:colors.backgroundGray,margin:10,
      borderRadius:5,color:colors.white},

      saveView:{alignItems:"flex-start"},

      btnSave:{padding:5,backgroundColor:colors.grayFont,borderRadius:5
        ,flexDirection:"row",alignItems:"center"},

        saveBtnText:{color:"#3C3C3C",fontSize:17}

    });