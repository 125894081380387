import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,Picker
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/stylesDark/AddCountryStyles'
import axios from 'axios';
import { api } from '../../../assets/assets';
import useaxios from '../../../assets/hooks/useaxios';

const AddCompany =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);
    const apilink='country';

    const [getaxios]=useaxios();

    const [countries, setcountries] = useState([]);
    const [cities, setcities] = useState([]);



    const [nameEN, setNameEN] = useState('');
    const [nameAR, setNameAR] = useState('');
    const [addressEN, setAddressEN] = useState('');
    const [addressAR, setAddressAR] = useState('');
    const [honarName, setHonarName] = useState('');
    const [country_id, setCountryId] = useState(0);
    const [city_id, setCityId] = useState(0);
    const [notes, setNotes] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
  
    const [error, setError] = useState(null);
  
    const handleAddBan = () => {
        if (!nameAR || !nameEN) {
          setError('NameAr and NameEN are required');
        } else {
          axios({
            method: 'post',
            url: 'http://127.0.0.1:8000/api/country',
            headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
            },
            data: {
              nameAr: nameAR,
              nameEN: nameEN,
            },
          })
          .then(response => {
            console.log(response.data);
            alert("done")
            setError('done');
            setNameAR('')
            setNameEN('')
          })
          .catch(error => {
            setError(error.message);
          });
        }
      };

      useEffect(() => {
      
        getaxios(apilink).then(data => setcountries(data)).catch(error => console.error(error));
        getaxios('cities').then(data => setcities(data)).catch(error => console.error(error));

      }, [])

     
    return(
      <SafeAreaView style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={1}/>
      <NavBar3 navigation={navigation} nav2active={1}/>



        
<Text style={{color:"white"}}>Add company page</Text>
          <View style={{justifyContent:"space-between"
      ,width:"100%"
      }}>
        <View style={styles.container}>

        <Text style={styles.inputHeaderText}>nameEN</Text>
      <TextInput
        value={nameEN}
        onChangeText={setNameEN}
        placeholder="nameEN"
        style={styles.TextInputTwoRow}
      />
      <Text style={styles.inputHeaderText}>nameAR</Text>
      <TextInput
        value={nameAR}
        onChangeText={setNameAR}
        placeholder="nameAR"
        style={styles.TextInputTwoRow}
      />
      <Text style={styles.inputHeaderText}>addressEN</Text>
      <TextInput
        value={addressEN}
        onChangeText={setAddressEN}
        placeholder="addressEN"
        style={styles.TextInputTwoRow}
      />
      <Text style={styles.inputHeaderText}>addressAR</Text>
      <TextInput
        value={addressAR}
        onChangeText={setAddressAR}
        placeholder="addressAR"
        style={styles.TextInputTwoRow}
      />
      <Text style={styles.inputHeaderText}>honarName</Text>
      <TextInput
        value={honarName}
        onChangeText={setHonarName}
        placeholder="honarName"
        style={styles.TextInputTwoRow}
      />



         <Text style={styles.inputHeaderText}>Country:</Text>
      <Picker
        selectedValue={country_id}
        onValueChange={(itemValue) => setCountryId(itemValue)}
        style={styles.TextInputTwoRow}

      >
        <Picker.Item label="-- Select a Country --" value={null}
                style={styles.TextInputTwoRow}

        />
        {countries.map((country) => (
          <Picker.Item key={country.id} label={country.nameAr} value={country.id}
          style={styles.TextInputTwoRow}

          />
        ))}
      </Picker>



      <Text style={styles.inputHeaderText}>city:</Text>
      <Picker
        selectedValue={city_id}
        onValueChange={(itemValue) => setCityId(itemValue)}
        style={styles.TextInputTwoRow}

      >
        <Picker.Item label="-- Select a city --" value={null}
                style={styles.TextInputTwoRow}

        />
        {cities.map((cities) => (
          <Picker.Item key={cities.id} label={cities.nameAr} value={cities.id}
          style={styles.TextInputTwoRow}

          />
        ))}
      </Picker>





      <Text style={styles.inputHeaderText}>city_id</Text>
      <TextInput
        value={city_id.toString()}
        onChangeText={(text) => setCityId(parseInt(text))}
        placeholder="city_id"
        keyboardType="numeric"
        style={styles.TextInputTwoRow}
      />
      <Text style={styles.inputHeaderText}>notes</Text>
      <TextInput
        value={notes}
        onChangeText={setNotes}
        placeholder="notes"
        style={styles.TextInputTwoRow}
      />
      <Text style={styles.inputHeaderText}>phoneNumber</Text>
      <TextInput
        value={phoneNumber}
        onChangeText={setPhoneNumber}
        placeholder="phoneNumber"
        style={styles.TextInputTwoRow}
      />
       
            <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.btnSave}        onPress={handleAddBan}
>
                <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            />
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>







        </View>
        </View>

           
</SafeAreaView>
    )
}

export default AddCompany;
