import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import axios from 'axios';
import {styles} from '../../../assets/styleSAP/formaInputSap'

import useTable from "../../../assets/hooks/useTable";
import { api } from '../../../assets/assets';
import TableShow from '../../table/TableShow';
import TableDelete from '../../table/TableDelete';





const SearchCategory = ({navigation}) => {
    
  const apilink='category';
  const pagetoEdit='EditCategory'

  const [data,setData]=useState([])
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const hide=[{header :"created_at"},{header :"updated_at"}
  // ,{header :"wieght"},{header :"hight"},{header :"gender"},{header :"birthday"}
]

  const[loading,setloading]=useState(0)

  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);


const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
      setloading(1)
    })
    .catch((error) => {
        console.log(error);
    });
}

  // Update the table data when the incoming data prop changes
  useEffect(() => {
    axios({
      method: "get",
      url: api+apilink,
      headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
      },
  })
  .then((response) => {
       console.log(response.data);
       setTableData(response.data);
       setData(response.data);
    setHeaderColumns(Object.keys(response.data[0] || {}));
    setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
    setloading(1)

  })
  .catch((error) => {
      console.log(error);
  });
    
  }, []);





 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  
  
  return (
    <SafeAreaView style={[styles.container,{minHeight:1080}]}>
    <NavBar navigation={navigation}/>
    <NavBar2 navigation={navigation} nav2active={3}/>
    <NavBar3 navigation={navigation} nav2active={2}/>



      
    <View style={styles.container2}>
        <Text >items table page</Text>

        {loading==0?null: <TableDelete
        apilink={apilink}
     pagetoEdit1={pagetoEdit}
     data1={data}
     tableData1={tableData}
     headerColumns1={headerColumns}
     headerColumnsOrginal1={headerColumnsOrginal}
     hide={hide}
     refresh={refresh}
     navigation={navigation}/>}

  </View>
  
  
  </SafeAreaView>
  );
  };
  
  

export default SearchCategory;