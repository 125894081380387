import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colors';

const { width, height } = Dimensions.get('window');

const Poppins_Medium = require('../fonts/Poppins-SemiBold.ttf');
const AlmaraiـBold = require('../fonts/Almarai/Almarai-Bold.ttf');



export const datePickerStyles = StyleSheet.create({
    container: {
        marginBottom:300,
        backgroundColor:"white"
       },
       datebtn:{
        padding:10,
        backgroundColor:colors.grayFont,
        width:400,
        margin:20,
        borderRadius:10
       }

     

    });