import {useDispatch,useSelector} from "react-redux";
import dataEntry from '../../assets/iconpng/dataEntry.png'
import shop from '../../assets/iconpng/shop.png'
import users from '../../assets/iconpng/users.png'
import subscribe from '../../assets/iconpng/subscribe.png'
import navs from "./navs";

const initalState = {
    
    remaining:0

      
};
const remainingReducer=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){



            

    case "remainin":
        return{...state,remaining:state.remaining==0?0:state.remaining-1 }
  
        case "addtoremainin":
            return{...state,remaining:state.remaining+1 }     

        case "setRemaining":
            return{...state,remaining:payload }






}



return state;
}
export default remainingReducer;