import {useDispatch,useSelector} from "react-redux";


const initalState = {
    addCountryView:"yes"


        

      
};
const countryReducer=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){
case "setaddCountryView":
    return{...state,addCountryView:state.addCountryView==="yes"?"no":"yes"}

    
            








}



return state;
}
export default countryReducer;