import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,StyleSheet,Modal,ScrollView
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'
import {searchPicker} from '../../../assets/stylesDark/searchPicker'
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import useaxios from '../../../assets/hooks/useaxios';
import { api } from '../../../assets/assets';


const AddTableMeals =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);

    const [error,seterror]=useState()
    const [loading,setloading]=useState(false)

    // select date 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datestring,setdatestring]= useState()
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
      setShowDatePicker(false);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      setdatestring(`${year}-${month}-${day}`)

      // axios({
      //   method: 'post',
      //   url: api+'searchChefMealsByDate',
      //   headers: { 
      //     'Accept': 'application/json',
      //     'Authorization': 'Bearer '
      //   },
      //   data:{
         
      //     date:`${year}-${month}-${day}`
        
      //   }
      // })
      // .then(response => {
      //  setItems(response.data)
      //  console.log(response.data)
      // })
      // .catch(error => {
      //   seterror(error.message);

      // });

     

    };

    const takeTheTable=()=>{
      axios({
        method: 'post',
        url: api+'searchChefMealsByDate',
        headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
        },
        data:{
         
          date:datestring
        
        }
      })
      .then(response => {
       setItems(response.data)
       console.log(response.data)
      })
      .catch(error => {
        seterror(error.message);

      });
    }
  
    const dateString = selectedDate ? selectedDate.toISOString().slice(0, 10) : '';
    // end select date 





    //categorychef
      const [categories,setcategories] = useState([])
      const [searchQuery, setSearchQuery] = React.useState('');
      const [selectedCategory, setSelectedCategory] = React.useState('');
      const [selectedcategoryName, setselectedcategoryName] = React.useState('');

      
      const [isPickerVisible, setPickerVisible] = React.useState(false);
      const [filteredCategories, setFilteredCategories] = React.useState(categories);
    
      const handleSearch = (query) => {
        const filtered = categories.filter((category) =>
          category.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCategories(filtered);
        setSearchQuery(query);
      };
    
      const handleValueChange = (value,name) => {
        setSelectedCategory(value);
        setPickerVisible(false);
        setselectedcategoryName(name)
      };
      // end of categorychef


      // meals 
      const [meals,setmeals] = useState([])
      const [mealsSearchQuery, setMealsSearchQuery] = useState('');
      const [selectedMealId, setSelectedMealId] = useState('');
      const [selectedMealName, setSelectedMealName] = useState('');

      const [isMealPickerVisible, setMealPickerVisible] = useState(false);
      const [filteredMeal, setFilteredMeal] = React.useState(meals);

      const handleSearchmeal= (query) => {
        const filtered = meals.filter((meal) =>
         meal.itemNameAR.toLowerCase().includes(query.toLowerCase())
         || meal.itemNameEN.toLowerCase().includes(query.toLowerCase())
       );
        setFilteredMeal(filtered);
        setMealsSearchQuery(query);
      };
    
      const handleMealValueChange = (value,name) => {
        setSelectedMealId(value);
        setMealPickerVisible(false);
        setSelectedMealName(name)
      };
      // end of meals



      // array of chef meals
      const [items, setItems] = React.useState([]);
      const addItemToArray = (array, selectedDate, selectedCategory,selectedcategoryName
        ,selectedMealId,selectedMealName
        ) => {


        const newItem = { 
          date:selectedDate,
          category_chef_id:selectedCategory ,
          item_id:selectedMealId,


          categoryNameAR:selectedcategoryName,
          itemNameAR:selectedMealName
        };
        const newArray = [...array, newItem];
        return newArray;
      };
      
      // Function to delete an item from the array
      const deleteItemFromArray = (array, index) => {
        const newArray = [...array];
        newArray.splice(index, 1);
        return newArray;
      };
      const handleAddItem = (selectedDate, selectedCategory,selectedcategoryName,selectedMealId,selectedMealName) => {
        if (!selectedDate || !selectedCategory || !selectedcategoryName || !selectedMealId || !selectedMealName) {
          // Handle the case where one or more variables are empty or undefined
          seterror("One or more variables are empty or undefined");
         
        }else{
          const newItems = addItemToArray(items, selectedDate, selectedCategory,selectedcategoryName,selectedMealId,selectedMealName);
          setItems(newItems);
          seterror('')
        }
       
      };
    
      const handleDeleteItem = (index) => {
        const newItems = deleteItemFromArray(items, index);
        setItems(newItems);
      };
      // end array of chef meals



      const saveChefMeals = ()=>{
        setloading(true)
        axios({
          method: 'post',
          url: api+'storechefmeals',
          headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
          },
          data:{
             data:items,
            
             meals:items,
            date:datestring
          
          }
        })
        .then(response => {
          console.log("yes")
          console.log(response.data);
         alert("done")
         axios({
          method: 'post',
          url: api+'storeNotselecttables',
          headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
          },
          data:{
             data:[],
            
             meals:[],
            date:datestring
          
          }
        })
        .then(response => {
          console.log("yes")
          console.log(response.data);
         alert("not select table deleted")
        //  axios({
        //   method: 'post',
        //   url: api+'deletAllMealsByDate',
        //   headers: { 
        //     'Accept': 'application/json',
        //     'Authorization': 'Bearer '
        //   },
        //   data:{
          
        //     date:datestring
          
        //   }
        // })
        // .then(response => {
        //   console.log("yes")
        //   console.log(response.data);
        //  alert("all subscribtion meals table deleted")
        //  setloading(false)
        // })
        // .catch(error => {
        //   console.log(error);

        // });
        setloading(false)
        })
        .catch(error => {
          console.log(error);

        });
        })
        .catch(error => {
          console.log(error);

        });
      }

      const[getaxios]=useaxios()
      useEffect(() => {
       getaxios('ChefMealsCategory').then(data => {
        setcategories(data)
        setFilteredCategories(data)
        console.log(data)
       }
        ).catch(error => console.error(error));

        getaxios('item').then(data => {
          setmeals(data)
          setFilteredMeal(data)
          console.log(data)
         }
          ).catch(error => console.error(error));

      }, [])

     
    return(
      loading?<Text>loading...</Text>:
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={1}/>



        
      <View style={styles.container2}>
        <Text >Add chef meals page</Text>

         {/*Date  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Date</Text>

   {/* Select the date*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the date</Text>
{!showDatePicker&& 
<TouchableOpacity onPress={() => setShowDatePicker(true)}>
<View   style={styles.TextInput}>
    <Text > {dateString}</Text>
    </View>
</TouchableOpacity>}



{showDatePicker && (
  <View  style={datePickerStyles.container}>
<DateTimePicker

value={selectedDate}
onChange={handleDateChange}
format="yyyy-MM-dd"
style={datePickerStyles.container}
/></View>)}

</View>
 {/*end of Select the date*/}

</View>
{/* end of Date */}


 {/*meals  */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>meals</Text>
{/* 2 row input */}
<View style={styles.ViewInput2Row}>

    {/* meal Category*/}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meal Category</Text>
<TouchableOpacity onPress={() => setPickerVisible(true)}
                    style={styles.TextInput}

            >
        
          <Text
          style={{textAlign:"left"}}
          >{ selectedcategoryName  +" "+ selectedCategory || 'Select a category'}</Text>
        
      </TouchableOpacity>
</View>
 {/*end ofmeal Category*/}


     {/* meal */}
     <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meal </Text>
<TouchableOpacity onPress={() => setMealPickerVisible(true)}
                    style={styles.TextInput}

            >
        
          <Text
          style={{textAlign:"left"}}
          >{ selectedMealName  +" "+ selectedMealId || 'Select a meal'}</Text>
        
      </TouchableOpacity>
</View>
 {/*end of meal */}

 <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={()=>
                handleAddItem(datestring,selectedCategory,selectedcategoryName
                  ,selectedMealId,selectedMealName
                  )}>
                    <Text style={styles.saveBtnText}>اضف الى الجدول </Text>
                </TouchableOpacity>
            </View>

            <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={()=> takeTheTable()}>
                    <Text style={styles.saveBtnText}>جلب الجدول</Text>
                </TouchableOpacity>
            </View>

</View>
{/*end of 2 row input */}

</View>
{/* end of meals */}





          
      <Modal visible={isPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearch}
              value={searchQuery}
              placeholder="Search categories"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredCategories.map((category, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleValueChange(category.id,category.categoryNameAR)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{category.categoryNameAR} {category.id}</Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

        </View>
      </Modal>
      {/* end of category select */}





      {/* select meals */}

         
      <Modal visible={isMealPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setMealPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchmeal}
              value={mealsSearchQuery}
              placeholder="Search meal"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredMeal.map((meal, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleMealValueChange(meal.id,meal.itemNameAR)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{meal.itemNameEN}{meal.itemNameAR} {meal.id}</Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>
      {/* end of category select */}











{/* add btn */}



  {/* end of add btn */}


  {/* {
    items&&items.map((item,index) => (
        <TouchableOpacity
        onPress={()=>handleDeleteItem(index)}
        key={index}
        >
            <Text>
              {item.date} + {item.category_chef_id} + {item.categoryNameAR}
            
            {item.item_id} {item.itemNameAR}
            </Text>
        </TouchableOpacity>
    ))
} */}
<View style={{marginVertical:15,marginHorizontal:10}}>
<View style={styles.tableHeaderView}>
        <Text  style={styles.cellHeader}>
            Date
          </Text>
          <Text  style={styles.cellHeader}>
            category
          </Text>
          <Text  style={styles.cellHeader}>
            meal arabic
          </Text>

          <Text  style={styles.cellHeader}>
            meal englich
          </Text>
          <Text  style={styles.cellHeader}>
            meal id
          </Text>
      </View>
{
    items&&items.map((item,index) => (
        <TouchableOpacity
        onPress={()=>handleDeleteItem(index)}
        style={styles.row}
        key={index}
        >
            <Text style={styles.cell}>
              {item.date} 
            </Text>
            <Text style={styles.cell}>
            {item.categoryNameAR}
            </Text>
            <Text style={styles.cell}>
            {item.itemNameAR}
            </Text>
            <Text style={styles.cell}>
            {item.itemNameEN}
            </Text>
            <Text style={styles.cell}>
            {item.item_id}
            </Text>
        </TouchableOpacity>
    ))
}
</View>



        </View>

            {/* add btn */}

<View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={()=>saveChefMeals()}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>  حفظ الجدول </Text>
                </TouchableOpacity>

            </View>

  {/* end of add btn */}
      

           
</View>
    )
}

export default AddTableMeals;
