import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colorsSAP';
const { width, height } = Dimensions.get('window');

const Poppins_Medium = require('../fonts/Poppins-SemiBold.ttf');
const AlmaraiـBold = require('../fonts/Almarai/Almarai-Bold.ttf');


export const styles = StyleSheet.create({

    navBar:{
        height:height*0.06,
        width:"100%",
        backgroundColor:colors.bgLightBlue,
        alignItems:"center",
        flexDirection:"row",
        padding:5,
        justifyContent:"space-between",
        borderWidth:0.5,borderColor:colors.bbgLightWhite,
        
    },
    navBarMenu:{
flexDirection:"row",        padding:5,

    },

    rightNavBar:{
        flexDirection:"row-reverse"
    },




    navBarButton:{
        marginRight: 10,
                borderRadius: 3,
                border: 1,
                borderColor: '#ddd',
                padding: 3,
                paddingLeft: 15,
                paddingRight: 15,
                backgroundColor: '#FBE09B',
                transition: 'all 0.1s ease-in',
                borderColor: '#707070',
                borderWidth:0.6,
                shadowColor: '#000000',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowRadius: 5,
                shadowOpacity: 1,
                flexDirection:"row"
    },
    navBarButtonLang:{
        margin:(height*0.02)/2,
        alignItems:"center",flexDirection:"row",
        padding:height*0.004,
        height:height*0.02, 
    },
    navBarButtonText:{
        fontFamily:Poppins_Medium,
        fontSize:16,
        color:colors.grayFont,
    },
    logoIcon:{width: 24, height: 24 ,marginRight:10 }
})