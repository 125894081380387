import { StyleSheet } from 'react-native';
import { View, Text, Dimensions } from 'react-native';
import colors from '../colors/colorsSAP';

const { width, height } = Dimensions.get('window');

const Poppins_Medium = require('../fonts/Poppins-SemiBold.ttf');
const AlmaraiـBold = require('../fonts/Almarai/Almarai-Bold.ttf');



export const styles = StyleSheet.create({
    container: {
        padding:"15%",
        backgroundColor:colors.bbgLightWhite,
       
       
      },
      loginDiv:{
        backgroundColor:colors.bgLightBlue,
        width: '55%',
        padding:30,
        alignSelf:"center",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        border: 1,
        borderColor: '#ddd',
        transition: 'all 0.1s ease-in',
        borderWidth:0.6,
        shadowColor: '#000000',
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowRadius: 5,
        shadowOpacity: 1,
      },
      logoimg:{
        width:width*0.1,
        height:width*0.1
      },
      
      langtext:{
        fontSize:17,fontFamily:AlmaraiـBold,
        
      },
      title: {
        fontSize: 24,
        fontFamily:AlmaraiـBold,
        marginBottom: 10,
        color:colors.grayFont
      },
      validationError: {
        color: 'red',
        marginBottom: 10,
      },
      inputDiv: {
        width: '100%',
        height:50,
        marginBottom: 10,
        backgroundColor:colors.bbgLightWhite,
                borderRadius: 3,
                border: 1,
                borderColor: '#ddd',
                padding: 3,
                paddingLeft: 15,
                paddingRight: 15,
                transition: 'all 0.1s ease-in',
                borderWidth:0.6,
                shadowColor: '#000000',
                shadowOffset: {
                  width: 0,
                  height: 3,
                },
                shadowRadius: 5,
                shadowOpacity: 1,
                flexDirection:"row"


        
      },
      input:{
        width:"100%", 
        padding: 10,  
        backgroundColor:colors.inputbg,    
        borderColor: colors.borderGray,
        color:colors.grayFont
      },
      divbutton: {
        width:170,
        height:'30%',
        margin:5,
        alignItems:"center",
        padding:5,
        backgroundColor:colors.bgLightYello,
        borderColor:"whie",
        borderRadius: 3,
        border: 1,
        borderColor: '#ddd',
        padding: 3,
        paddingLeft: 15,
        paddingRight: 15,
        transition: 'all 0.1s ease-in',
        borderWidth:0.6,
        shadowColor: '#000000',
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowRadius: 5,
        shadowOpacity: 1,
      },
      button:{
        width:170,height:"100%", padding: 10,alignItems:"center",flexDirection:"row",justifyContent:"space-between"
   

    
    },
      buttonText: {
        color:colors.grayFont,
        fontWeight: 'bold',
      },
      resetPasswordButton: {
        marginTop: 20,
      },
      resetPasswordButtonText: {
        color:colors.grayFont,
        fontWeight: 'bold',

      },
});
