import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';

const areas = [
  { area_name: 'kuwait', area_id: 1 },
  { area_name: 'salmi', area_id: 2 },
];
// calorie club
// const colorsPackage = [
//   { label: 'Red', value: 'red' },
//   { label: 'Yellow', value: 'yellow' },
//   { label: 'Light Green', value: 'lighGreenBtn' },
// ];

// dr chef colors
const colorsPackage = [
  { label: 'Red', value: 'red' },
  { label: 'yellwo', value: 'yellow' },
  { label: 'darkyellow', value: 'darkyellow' },
  { label: 'darkRed', value: 'darkRed' },
  { label: 'tapfont', value: 'tapfont' },
];


const EditPackage =({navigation,route})=>{
    const { item} = route.params;
    const nav=useSelector(state =>state.navbar.nav2Active);

    const [error, setError] = useState(null);
  
    const [packageNameAR, setPackageNameAR] = useState(item.packageNameAR);
  const [packageNameEN, setPackageNameEN] = useState(item.packageNameEN);
  const [price, setPrice] = useState(item.price);
  const [discount, setDiscount] = useState(item.discount);
  const [meals, setMeals] = useState(item.meals);
  const [snacks, setSnacks] = useState(item.snacks);
  const [descriptionAR, setDescriptionAR] = useState(item.descriptionAR);
  const [descriptionEN, setDescriptionEN] = useState(item.descriptionEN);
  const [protein, setProtein] = useState(item.protein);
  const [carb, setCarb] = useState(item.carb);
  const [calories, setCalories] = useState(item.Calories);
  const [caloriesWidth, setCaloriesWidth] = useState(item.CaloriesWidth);
  const [isHide, setIsHide] = useState(item.is_hide);
  const [selectedColor, setSelectedColor] = useState(item.color);

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleSubmit = () => {
    // Check if packageNameAR is empty
    if (!packageNameAR.trim()) {
      setError('Package name in Arabic is required');
      return;
    }
  
    // Check if packageNameEN is empty
    if (!packageNameEN.trim()) {
      setError('Package name in English is required');
      return;
    }
  
    // Check if price is a valid number
    if (isNaN(parseFloat(price)) || !price.toString().trim()) {
      setError('Price must be a valid number');
      return;
    }
  
    // Check if discount is a valid number
    if (isNaN(parseFloat(discount))) {
      setError('Discount must be a valid number');
      return;
    }
  
    // Check if meals is a valid number
    if (isNaN(parseInt(meals)) || parseInt(meals) < 1 || parseInt(meals) > 6) {
      setError('Number of meals must be between 1 and 6');
      return;
    }
  
    // Check if snacks is a valid number
    if (isNaN(parseInt(snacks)) || parseInt(snacks) < 1 || parseInt(snacks) > 6) {
      setError('Number of snacks must be between 1 and 6');
      return;
    }
  
    // Check if descriptionAR is empty
    if (!descriptionAR.trim()) {
      setError('Description in Arabic is required');
      return;
    }
  
    // Check if descriptionEN is empty
    if (!descriptionEN.trim()) {
      setError('Description in English is required');
      return;
    }
  
    // Check if protein is a valid number
    if (isNaN(parseInt(protein)) || parseInt(protein) < 10 || parseInt(protein) > 300) {
      setError('Protein must be between 10 and 300');
      return;
    }
  
    // Check if carb is a valid number
    if (isNaN(parseInt(carb)) || parseInt(carb) < 10 || parseInt(carb) > 300) {
      setError('Carbohydrates must be between 10 and 300');
      return;
    }
  
    // Check if calories is a valid number
    if (isNaN(parseInt(calories)) || parseInt(calories) < 100 || parseInt(calories) > 3000) {
      setError('Calories must be between 100 and 3000');
      return;
    }
  
    // Check if caloriesWidth is empty
    if (!caloriesWidth.trim()) {
      setError('Calories width is required');
      return;
    }
  
    // If all checks pass, clear any existing error and submit the form
    setError(null);
    
    // Do something with the form data, such as sending it to a server or storing it in state
    const formData = {
      packageNameAR:packageNameAR,
      packageNameEN:packageNameEN,
      price: parseFloat(price),
      discount: parseFloat(discount) || 0,
      meals: parseInt(meals),
      snacks: parseInt(snacks),
      descriptionAR:descriptionAR,
      descriptionEN:descriptionEN,
      protein: parseInt(protein),
      carb: parseInt(carb),
      Calories: parseInt(calories),
      CaloriesWidth:caloriesWidth,
      is_hide: isHide,
      color:selectedColor
    };
    
    console.log('Form data:', formData);
    axios({
        method: "put",
        url: api+"Package/"+item.id,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:formData
    })
    .then((response) => {
         console.log(response.data);
         alert("done")
    })
    .catch((error) => {
    //   console.log(error.response.data.data['phone']=="The phone has already been taken."?setError("The phone has already been taken."):setError("error server"));
    console.log(error)
    });
  };


     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={3}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
      <View style={styles.container2}>
        <Text >Add Package page</Text>



        {/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>genral info</Text>

 {/* 4 row input */}
 <View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>


 {/* Package Name (Arabic)*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name (Arabic)</Text>
<TextInput
        value={packageNameAR}
        onChangeText={setPackageNameAR}
        style={styles.TextInput}
      />
</View>
 {/*end of Package Name (Arabic)*/}

  {/* Package Name (English)*/}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name (English)</Text>
<TextInput
  value={packageNameEN}
        onChangeText={setPackageNameEN}
        style={styles.TextInput}
      />
</View>
 {/*end of Package Name (English)*/}


  {/* Description (Arabic)*/}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Description ar</Text>
<TextInput
        multiline
        value={descriptionAR}
        onChangeText={setDescriptionAR}
        style={styles.TextInput}
      />
</View>
 {/*end of Description (Arabic)*/}

   {/* Description (English)*/}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Description en</Text>
<TextInput
        multiline
        value={descriptionEN}
        onChangeText={setDescriptionEN}
        style={styles.TextInput}
      />
</View>
 {/*end of Description (English)*/}

</View>
 {/* end of 4 row input */}

</View>
        {/* end of genral info  */}






            <View style={styles.container}>
      

          {/* meals info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>meals info</Text>

 {/* 4 row input */}
 <View style={styles.ViewInput4Row}>


 {/* Meals*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Meals</Text>
<Picker
        selectedValue={meals}
        onValueChange={setMeals}
        style={styles.TextInput}
      >
        {[...Array(6)].map((_, i) => (
          <Picker.Item key={i + 1} label={`${i + 1}`} value={`${i + 1}`} />
        ))}
      </Picker>
</View>
 {/*end of Meals*/}


  {/* Snacks*/}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Snacks</Text>
<Picker
        selectedValue={snacks}
        onValueChange={setSnacks}
        style={styles.TextInput}

      >
        {[...Array(6)].map((_, i) => (
          <Picker.Item key={i + 1} label={`${i + 1}`} value={`${i + 1}`} />
        ))}
      </Picker>
</View>
 {/*end of Snacks*/}


   {/* Protein*/}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Protein</Text>
<Picker
        selectedValue={protein}
        onValueChange={setProtein}
        style={styles.TextInput}
      >
        {[...Array(30)].map((_, i) => (
          <Picker.Item key={i + 1} label={`${(i + 1) * 10}`} value={`${(i + 1) * 10}`} />
        ))}
      </Picker>
</View>
 {/*end of Protein*/}

    {/* Carb*/}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Carb</Text>
<Picker
     selectedValue={carb}
     onValueChange={setCarb}
     style={styles.TextInput}
   >
{[...Array(30)].map((_, i) => (
<Picker.Item key={i + 1} label={`${(i + 1) * 10}`} value={`${(i + 1) * 10}`} />
))}
</Picker>
</View>
 {/*end of Carb*/}
 </View>
  {/* end of 4 row input */}


  {/* 2 row input */}
  <View style={styles.ViewInput2Row}>

        {/* Calories*/}
        <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Calories</Text>
<Picker
    selectedValue={calories}
    onValueChange={setCalories}
    style={styles.TextInput}
  >
    {[...Array(30)].map((_, i) => (
      <Picker.Item key={i + 1} label={`${(i + 1) * 100}`} value={`${(i + 1) * 100}`} />
    ))}
  </Picker>
</View>
 {/*end of Calories*/}


  {/* Calories Width*/}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Calories Width</Text>
<TextInput
    value={caloriesWidth}
    onChangeText={setCaloriesWidth}
    style={styles.TextInput}

  />
</View>
 {/*end of Calories Width*/}


</View>
  {/* end of 2 row input */}

 </View>
          {/* end of meals info  */}


          {/* price info  */}
          <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>price info</Text>

  {/* 2 row input */}
  <View style={styles.ViewInput2Row}>

      {/* Price*/}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Price</Text>
<TextInput
        keyboardType="numeric"
        value={price}
        onChangeText={setPrice}
        style={styles.TextInput}
      />
</View>
 {/*end of Price*/}

      {/* Discount*/}
      <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Discount</Text>
<TextInput
        keyboardType="numeric"
        value={discount}
        onChangeText={setDiscount}
        style={styles.TextInput}
      />
</View>
 {/*end of Discount*/}

    
  </View>
  {/* end of 2 row input */}

</View>
      {/* end of price info  */}


       {/* Hide info  */}
       <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Hide info</Text>

{/* 2 row view */}
<View style={styles.ViewInput2Row}>

    {/* package color */}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>color Package</Text>
<Picker
      selectedValue={selectedColor}
      onValueChange={handleColorChange}
    >
      {colorsPackage.map(({ label, value }) => (
        <Picker.Item key={value} label={label} value={value} />
      ))}
    </Picker>
              

</View>
 {/*end of package color */}




    {/* Hide Package*/}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Hide Package</Text>
<Picker
    selectedValue={isHide}
    onValueChange={setIsHide}
    style={styles.TextInput}
  >
    <Picker.Item label="No" value="no" />
    <Picker.Item label="Yes" value="yes" />
  </Picker>
              

</View>
 {/*end of Hide Package*/}

</View>
      {/* end of Hide info  */}


      </View>
{/*end of 2 row view */}
    


  

 

     



 
</View>


        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        
   onPress={handleSubmit}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>update</Text>
                </TouchableOpacity>

            </View>
        

           
</View>
    )
}

export default EditPackage;