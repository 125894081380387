import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/stylesDark/AddCountryStyles'
import axios from 'axios';

const Country =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);

    const [nameAr, setNameAr] = useState('');
    const [nameEN, setNameEN] = useState('');
    const [error, setError] = useState(null);
  
    const handleAddBan = () => {
        if (!nameAr || !nameEN) {
          setError('NameAr and NameEN are required');
        } else if (/^[a-zA-Z\s]*$/.test(nameAr) && /^[a-zA-Z\s]*$/.test(nameEN)) {
          axios({
            method: 'post',
            url: 'http://127.0.0.1:8000/api/country',
            headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
            },
            data: {
              nameAr: nameAr,
              nameEN: nameEN,
            },
          })
          .then(response => {
            console.log(response.data);
            setError('done');
          })
          .catch(error => {
            setError(error.message);
          });
        } else {
          setError('NameAr and NameEN should only contain letters and spaces');
        }
      };

      useEffect(() => {
        setNameAr('')
        setNameEN('')
        setError('')
      }, [nav])
    return(
      <SafeAreaView style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={1}/>
      <NavBar3 navigation={navigation}/>



        
<Text style={{color:"white"}}>country page</Text>
          <View style={{justifyContent:"space-between"
      ,width:"100%"
      }}>
        <View style={styles.container}>

            <View style={styles.TwoRowInputView}>

            <Image 
                source={world}  
                style={{width: 24, height: 24 ,margin:5}}
            />
            <Text style={styles.inputHeaderText}>
                country name in arabic
            </Text>

            <TextInput
            
            value={nameAr}
            onChangeText={setNameAr}
            placeholder="Name in Arabic"
            style={styles.TextInputTwoRow}
            />
            <Image 
                source={world}  
                style={{width: 24, height: 24 ,margin:5}}
            />
<Text style={styles.inputHeaderText}>
                country name in english
            </Text>

            <TextInput
            value={nameEN}
            onChangeText={setNameEN}
            placeholder="Name in English"
            style={styles.TextInputTwoRow}
            />


            </View>

            <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.btnSave}        onPress={handleAddBan}
>
                <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            />
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>







        </View>
        </View>

           
</SafeAreaView>
    )
}

export default Country;
