import React, { useState } from 'react'
import {View,Text,SafeAreaView,TouchableOpacity} from 'react-native'
import NavBar from '../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../assets/colors/colors';
import NavBar2 from '../navbar/NavBar2';
import NavBar3 from '../navbar/NavBar3';

const Citys =({navigation})=>{
    const dispatch = useDispatch();
    const [search,setSearch]=useState(1);
    const [add,setAdd]=useState(1);
    const[analysis,setAnalysis]=useState(1);

    return(
        <SafeAreaView style={{backgroundColor:colors.backgroundGray}}>
            <NavBar navigation={navigation}/>
            <NavBar2 navigation={navigation} nav2active={2}/>
            <NavBar3 navigation={navigation}/>



              
<Text style={{color:"white"}}>Citys page</Text>
                <View style={{backgroundColor:colors.white,flexDirection:"row",justifyContent:"space-between"
            ,width:"100%"
            }}>
                {
                    add===1?
                    <View>
                    <View style={{backgroundColor:"blue",width:"100%", padding:20}}>
                        <TouchableOpacity onPress={()=>setAdd(0)
                        }>
                            X
                        </TouchableOpacity>
                    <Text>add</Text>
                    </View></View>:null

                }   
                
                {
                    search===1?
                    <View>
                <View style={{backgroundColor:"red",width:"100%"}}>
                <TouchableOpacity onPress={()=>setSearch(0)
                        }>
                            X
                        </TouchableOpacity>
                    <Text>tabel</Text>
                    </View></View>
                    :null
                }
                



                    {
                        analysis===1?
                        <View>
                <View style={{backgroundColor:"white",width:"100%"}}>
                <TouchableOpacity onPress={()=>setAnalysis(0)
                        }>
                            X
                        </TouchableOpacity>
                    <Text>analysis</Text>
                </View>
                </View>
                        :null
                    }
                    



                </View>

           
        </SafeAreaView>
    )
}

export default Citys;
