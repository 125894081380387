import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';





const AddCategoryChef =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);
    const [error,seterror]=useState('')

    const [categoryAR, setCategoryAR] = useState('');
  const [categoryEN, setCategoryEN] = useState('');
  const [type, setType] = useState(null);
  const handleTypeChange = (value) => {
    setType(value);
  };

    useEffect(() => {
      
    //   setTimeout(function() {getCategoryis()}, 2000);
    }, [])

    const handleSubmit = () => {
        if (categoryAR.trim() === '' || categoryEN.trim() === '') {
          alert('Please enter a category in both languages');
          seterror('Please enter a category in both languages')
          return;
        }
        else{
            const data={
              categoryNameAR:categoryAR,
              categoryNameEN:categoryEN,
                type:type
            }


            axios({
                method: "post",
                url: api+'ChefMealsCategory',
                headers: { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer '
                },
                data:data
            })
            .then((response) => {
                 console.log(response.data);
                 alert("donae")
                 setCategoryAR('');
                 setCategoryEN('');
            })
            .catch((error) => {
                console.log(error);
            });

            
      
        }
    
      
      };
    
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={4}/>



        
      <View style={styles.container2}>
        <Text >Add Category chef page</Text>



          {/* all text input */}
 
 {/* Category  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Category</Text>


<View style={styles.ViewInput2Row}>

   {/* input Category (Arabic) */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Category (Arabic)</Text>
<TextInput
        placeholder="Category (Arabic)"
        value={categoryAR}
        onChangeText={text => setCategoryAR(text)}
        style={styles.TextInput}

      />
</View>
 {/* end of input Category (Arabic) */}

    {/* input Category (English) */}
    <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Category (English)</Text>
<TextInput
        placeholder="Category (English)"
        value={categoryEN}
        onChangeText={text => setCategoryEN(text)}
        style={styles.TextInput}
      />
</View>
 {/* end of input Category (English) */}

 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>type</Text>
<Picker
        selectedValue={type}
        onValueChange={handleTypeChange}
      >
        <Picker.Item label="Select Type" value={null} />
        <Picker.Item label="Meal" value="meal" />
        <Picker.Item label="Snack" value="snack" />
      </Picker>
</View>


</View>

</View>
      {/* end of Category  */}

          
     





      










          







        
        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
   onPress={handleSubmit}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default AddCategoryChef;