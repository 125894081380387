import {combineReducers} from "redux";
import countryReducer from "./reducer/countryReducer";
import language from './reducer/language'
import login from "./reducer/login";
import navbar from "./reducer/navbar";
import navbar2Reducer from "./reducer/navbar2Reducer";
import navbar3Reducer from "./reducer/navbar3Reducer";
import remainingReducer from "./reducer/remainingReducer";
import theme from "./reducer/theme";

const rootReducer = combineReducers({
    language:language,
    navbar:navbar,
    navbar2Reducer:navbar2Reducer,
    navbar3Reducer:navbar3Reducer,
    login:login,
    theme:theme,
    countryReducer:countryReducer,
    remainingReducer:remainingReducer
 
})


export default rootReducer;